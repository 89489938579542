import React, { useEffect, useState } from "react";
import axios from "axios";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {Staff} from "../../../types/types";

interface StaffProps {
  business_id: number;
  business_name: string;
}

const StaffComponent: React.FC<StaffProps> = ({ business_id, business_name }) => {
  const [staffArray, setStaffArray] = useState<Staff[]>([]);
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    axios
      .get(`/api/business/get/staff/${business_id}`)
      .then((response) => {
        setStaffArray(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });

    // Check if the device is a mobile
    const isMobileDevice = () => {
      return window.innerWidth <= 768; // Adjust the breakpoint as per your design
    };

    // Set initial state
    setIsMobile(isMobileDevice());

    // Event listener for window resize
    const handleResize = () => {
      setIsMobile(isMobileDevice());
    };

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Clean up
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [business_id]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!staffArray.length) {
    return <div></div>;
  }

  const isDesktop = !isMobile;

  // Check if staff length is not 3 and it's desktop
  if (isDesktop && staffArray.length < 3) {
    return (
      <div className="listingSection__wrap flex pb-12">
        <h2 className="font-bold text-xl font-semibold">Team</h2>
        <div className="grid grid-cols-3 gap-4">
          {staffArray.map((staff) => (
            <div key={staff.id} className="text-center">
              <div className="staff-profile drop-shadow-md rounded-full w-32 h-32 mx-auto mb-4 overflow-hidden">
                <img
                  src={staff.profile}
                  alt={staff.name + " " + business_name}
                  title={staff.name + " " + business_name}
                  className="rounded-full w-full h-full object-cover drop-shadow-lg"
                />
              </div>
              <div className="mt-2">
              <h3 className="text-sm font-semibold">{staff.name}</h3>
              <h4 className="text-xs text-gray-500">{staff.job_title}</h4>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  // Render Carousel if staff length is 3 or it's not desktop
  return (
    <div className="listingSection__wrap flex pb-12">
      <h2 className="font-bold text-xl font-semibold">{business_name}&nbsp;Professionals</h2>
      <div className="  lg:mb-0 relative ">
        <Carousel
          showArrows={!isMobile}
          infiniteLoop={true}
          autoPlay={true}
          interval={2000}
          showStatus={false}
          stopOnHover={false}
          swipeable={true}
          emulateTouch={true}
          showIndicators={false}
          renderArrowNext={(onClickHandler, hasNext, label) =>
            hasNext && (
              <NextArrow onClick={onClickHandler} label={label} />
            )
          }
          renderArrowPrev={(onClickHandler, hasPrev, label) =>
            hasPrev && (
              <PrevArrow onClick={onClickHandler} label={label} />
            )
          }
          centerMode={!isMobile}
          centerSlidePercentage={33.33}
        >
          {staffArray.map((staff) => (
            <div key={staff.id} className="text-center h-56 items-center flex">
            <div className="drop-shadow-md rounded-full w-32 h-32 mx-auto">
              <img
                src={staff.profile}
                alt={staff.name + " " + business_name}
                title={staff.name + " " + business_name}
                className="rounded-full w-full h-full object-cover drop-shadow-lg"
              />
             <div className="mt-2 ">
             <h3 className="text-sm font-semibold">{staff.name}</h3>
             <h4 className="text-xs text-gray-500">{staff.job_title}</h4>
             </div>
            </div>
          </div>
          ))}
        </Carousel>
      </div>
    </div>
  );
};

const NextArrow: React.FC<any> = ({ onClick }) => {
  return (
    <div
      className="rounded-full bg-gray-200 p-2 cursor-pointer hover:bg-gray-300 transition duration-300 absolute right-0 top-1/2 transform -translate-y-1/2"
      onClick={onClick}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
      </svg>
    </div>
  );
};

const PrevArrow: React.FC<any> = ({ onClick }) => {
  return (
    <div
      className="rounded-full bg-gray-200 p-2 cursor-pointer hover:bg-gray-300 transition duration-300 absolute left-0 top-1/2 transform -translate-y-1/2"
      onClick={onClick}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
      </svg>
    </div>
  );
};

export default StaffComponent;
