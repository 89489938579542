import React, { useEffect, useState } from 'react';
import { OpeningHours } from '../../../types/types';

interface SalonOpeningHoursProps {
  business_id: number;
  status: string;
  business_name: string;
}

const SalonOpeningHours: React.FC<SalonOpeningHoursProps> = ({ business_id, status, business_name }) => {
  const [openingHours, setOpeningHours] = useState<OpeningHours | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('/api/business/post/openinghours', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ business_id: business_id }),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data: OpeningHours = await response.json();
        setOpeningHours(data);
      } catch (error) {
        console.error('Error fetching opening hours:', error);
      }
    };

    fetchData();
  }, [business_id]);

  // Function to format time to AM/PM
  const formatTime = (time: string) => {
    const timeRegex = /^([01]\d|2[0-3]):([0-5]\d)$/;
    if (!timeRegex.test(time)) {
      return "Closed";
    }
    const [hours, minutes] = time.split(':');
    const hour = parseInt(hours);
    const period = hour >= 12 ? 'PM' : 'AM';
    const formattedHour = hour % 12 === 0 ? '12' : (hour % 12).toString();
    return `${formattedHour}:${minutes} ${period}`;
  };

  return (
    <div className="listingSection__wrap space-y-6 mt-4 mb-4">
      <div className="flex items-center mb-4">
        <h2 className="text-xl font-semibold mt-4 mb-4">Opening Hours of {business_name}</h2>
        {/* {status === 'closed' ? (
          <div className="flex items-center justify-center bg-red-500 text-white py-2 px-4 rounded">closed</div>
        ) : (
          <div className="flex items-center justify-center bg-green-500 text-white py-1 px-2 rounded">open</div>
        )} */}
      </div>
      <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
        {['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'].map((day) => {
          const openingKey = `${day}_opening` as keyof OpeningHours;
          const closingKey = `${day}_closing` as keyof OpeningHours;
          return (
            <div
              key={day}
              className={`p-2 rounded-lg shadow ${
                day === new Date().toLocaleString('en-us', { weekday: 'long' }).toLowerCase() ? 'text-purple-500' : 'bg-white'
              }`}
            >
              <p className="font-semibold">{day.charAt(0).toUpperCase() + day.slice(1)}</p>
              <p>
                {openingHours ? formatTime(openingHours[openingKey]) : "closed"} - {openingHours ? formatTime(openingHours[closingKey]) : "closed"}
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SalonOpeningHours;
