import React, { FC, useState, useEffect } from "react";
import Logo from "shared/Logo/Logo";
import AvatarDropdown from "./AvatarDropdown";
import Navigation from "shared/Navigation/Navigation";
import MenuBar from "shared/MenuBar/MenuBar";
import HeroSearchForm2MobileFactory from "components/HeroSearchForm2Mobile/HeroSearchForm2MobileFactory";
import DropdownTravelers from "./DropdownTravelers";

export interface MainNav1Props {
  className?: string;
}

const MainNav1: FC<MainNav1Props> = ({ className = "" }) => {

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const cookies = document.cookie.split('; ');
    let userId = null;

    for (const cookie of cookies) {

      const [name, value] = cookie.split('=');
      if (name === 'userId') {
        setIsLoggedIn(true);
        break;
      }
      else {
        setIsLoggedIn(false);
      }
    }
  })


  return (
   <>
    <div className="z-50 relative bg-white border-b">
      <div className="px-4 hidden md:container md:py-5 items-center md:flex">
        <div className="w-[80%] md:w-full flex items-center justify-start">
          <Logo className="mr-4" />
          <DropdownTravelers />
          <div className="md:hidden lg:flex">
          <Navigation />
          </div>
        </div>

        

        

          {
            isLoggedIn ? (
              <>
              <div className="md:flex md:w-[90%] md:justify-end lg:w-[30%] mx-auto items-center">
                <a className="  items-center py-2 px-2 xl:px-5 rounded-full hover:text-neutral-900 hover:bg-neutral-100 dark:hover-bg-neutral-800 dark:hover:text-neutral-200 " href="/orders">My Bookings</a>
                <a href="/dashboard" title="Feelaxo - Customer Dashboard" className=" flex py-2 px-3 items-center mr-4 rounded-full hover:text-neutral-900 hover:bg-neutral-100 dark:hover-bg-neutral-800 dark:hover:text-neutral-200">Account</a> <AvatarDropdown />
                </div>
              </>
            ) : (
              <>
              <div className="md:flex md:justify-around md:w-[90%] lg:w-[50%] mx-auto items-center">
                  <a className=" items-center py-2  xl:px-5 rounded-full hover:text-neutral-900 hover:bg-neutral-100 dark:hover-bg-neutral-800 dark:hover:text-neutral-200 " href="https://pos.feelaxo.com/">
                    List your business</a>
                  <a href="/login" title="Feelaxo - Customer Login"><button className="hover:bg-grey-300 bg-black text-white pt-2 items-center pb-2 pl-8 pr-8 rounded-full">Log In</button></a>
                  <MenuBar />
                </div>
              </>
            )
          }
      </div>

      <div className="md:hidden py-3 px-3 flex-[3] max-w-lg !mx-auto md:px-3">
          <HeroSearchForm2MobileFactory />
        </div>

    </div>
   </>
  );
};

export default MainNav1;
