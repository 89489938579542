import React, { useState, useEffect } from 'react';
import axios from "axios";
import OrderDetails from './OrderDetail';
import AddReview from './AddReview';
import { Service } from "../../types/types";

interface OrderProps {
  appointment_id: string;
  appointmentDate: Date;
  appointmentTime: string;
  itemsSelected: Array<Service>;
  cartTotal: string;
  paymentId: string;
  paymentType: string;
  created_at: string;
  otp: number;
  invoice: string;
  status: string;
  business_id: number;
  staffMemberId: number;
  couponCode: string;
}

const OrderPage = () => {
  const [empty, setEmpty] = useState(false);
  const [orderList, setOrderList] = useState<(OrderProps | null)[]>([]);
  const [expandedOrder, setExpandedOrder] = useState<OrderProps | null>(null);
  const [DetailsOpen, setDetailsOpen] = useState(false);

  const [showReview, setShowReview] = useState(false);
  // const [reviewId, setreviewId] = useState<number |

  const readCookie = (cookieName: string): string | null => {
    const nameEQ = cookieName + "=";
    const cookies = document.cookie.split(";");

    for (let i = 0; i < cookies.length; i++) {
      let cookie = cookies[i];
      while (cookie.charAt(0) === " ") {
        cookie = cookie.substring(1, cookie.length);
      }
      if (cookie.indexOf(nameEQ) === 0) {
        return cookie.substring(nameEQ.length, cookie.length);
      }
    }
    return null;
  };

  const userId = readCookie("userId");

  useEffect(() => {
    if (userId) {
      axios.post("/api/order/get/orderList", {
        userId: userId
      }).then(async (response) => {
        if (response.data.message === "404") {
          setEmpty(true);
        } else {
          const data = await response.data;
          // Sort the order list by created_at
          const sortedOrders = data.orders.sort((a: OrderProps, b: OrderProps) => {
            return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
          });
          setOrderList(sortedOrders);
        }
      }).catch((error) => {
        console.log(error);
      });
    }
  }, [userId]);

  const formatDate = (dateString: Date) => {
    const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString('en-US', options);
  };

  const formatTime = (timeString: string) => {
    const options: Intl.DateTimeFormatOptions = { hour: '2-digit', minute: '2-digit', second: '2-digit' };
    return new Date(`2000-01-01T${timeString}`).toLocaleTimeString('en-US', options);
  };

  return (
    <>
      {/* <CommonLayout> */}
        <div className="ordersContainer mt-20 mb-20">
          <div>
            <h1 className="text-center text-2xl font-bold">Your Booking History</h1>
            {(orderList.length !== 0) ? (
              <div className="flex flex-wrap justify-center">
                {orderList.map((order, index) => (
                  <div key={index} className={`w-80 mx-4 my-4 p-6 bg-white rounded-md shadow-md`}>
                    {order && (
                      <>
                        
                        <p>Order ID: {order.appointment_id} <div className={`inline-block rounded-md text-center p-2 float-right ${getStatusColor(order?.status)}`}>{order.status}</div></p>
                        <p className="font-bold">{order.itemsSelected.map(item => item.name).join(' + ')}</p>
                        <p>Date: {formatDate(order.appointmentDate)}</p>
                        <p>Time: {formatTime(order.appointmentTime)}</p>
                        
                        {
                          (order.invoice != null) && (
                            <a href={order.invoice} className="text-blue-500 hover:underline block mb-4">Download Invoice</a>
                          )
                        }
                        {
                          (order.status == 'pending') && (
                            <p>Order yet to confirm</p>
                          )
                        }
                        <button
                          className="bg-black w-64 mx-auto text-white px-4 py-2 rounded-md mb-4"
                          onClick={() => {
                            setExpandedOrder(order)
                            setDetailsOpen(true)
                          }}
                        >
                          Show Details
                        </button>

                        {
                          order.status == "completed" && (
                            <div
                          className="mx-auto text-blue-500 text-center cursor-pointer px-4 py-2 rounded-md mb-4"
                          onClick={() => {
                            setShowReview(true)
                          }}
                        >
                          Review
                        </div>
                          )
                        }

                      </>
                    )}
                  </div>
                ))}
              </div>
            ) : (
              <h1 className="text-center text-lg text-gray-300">You do not have any orders</h1>
            )}
          </div>
        </div>
      {/* </CommonLayout> */}

      {(DetailsOpen) && (
        <OrderDetails orderDetails={expandedOrder} closeDetails={() => { setDetailsOpen(false) }} />
      )}

      {/* {(showReview) && (
        <AddReview businessId={order.business_id} closeReview={ () => {setShowReview(false)}} orderId={order.appointment_id} />
      )} */}
    </>
  );
};

// Helper function to get status color
const getStatusColor = (status: string | undefined): string => {
  switch (status) {
    case 'confirmed':
      return 'bg-blue-200 border border-blue-400 text-blue-500';
      case 'booked':
      return 'bg-blue-200 border border-blue-400 text-blue-500';
    case 'completed':
      return 'bg-green-200 border border-green-400 text-green-500';
    case 'pending':
      return 'bg-yellow-200 border border-yellow-500 text-yellow-500'; 
    case 'cancelled':
      return 'bg-red-200 border border-red-400 text-red-500';
    default:
      return 'bg-white';
  }
}

export default OrderPage;
