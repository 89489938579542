import React, { FC, useState, useEffect } from "react";
import HeroSearchForm from "components/HeroSearchForm/HeroSearchForm";

interface Media {
  type: 'video' | 'image';
  src: string;
}

const media: Media[] = [
  {
    type: 'video',
    src: "https://nestcraft.sirv.com/Feelaxo/homevideo.mp4",
  },

];

interface SectionHeroProps {
  className?: string;
}

const SectionHero: FC<SectionHeroProps> = () => {
  const [currentMediaIndex, setCurrentMediaIndex] = useState<number>(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentMediaIndex((prevIndex) => (prevIndex + 1) % media.length);
    }, 5000);

    return () => clearInterval(intervalId);
  }, []);

  const currentMedia = media[currentMediaIndex];

  const backgroundStyle: React.CSSProperties = {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  };

  return (
    <div className="relative w-full h-[90vh] flex items-center justify-center">
      {currentMedia.type === 'video' ? (
        <video autoPlay muted loop style={backgroundStyle}>
          <source src={currentMedia.src} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      ) : (
        <img src={currentMedia.src} alt="Background" style={backgroundStyle} />
      )}
      <div className="container absolute inset-0 flex justify-center flex-col">
        <h1 className="marcellus-regular text-white text-center text-4xl font-bold pb-4" style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}>
        Treat yourself today!
        </h1>
        <h1 className="text-white text-center text-2xl font-semibold pb-4" style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}>
        Book Appointments for top Rated Salons and SPA near you
        </h1>
          <HeroSearchForm className="text-white border-white" fill="rgb(255, 255, 255)" />
      </div>
    </div>
  );
};

export default SectionHero;
