import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {Staff} from "../../../types/types";

interface StaffSelectionProps {
    businessId?: number;
    setStaff: (staff: Staff) => void;
    selectedStaff: Staff | null;
}

const StaffSelection: React.FC<StaffSelectionProps> = ({ businessId, setStaff, selectedStaff }) => {
    const [staffList, setStaffList] = useState<Staff[]>([]);
    const [selectedStaffIndex, setSelectedStaffIndex] = useState<number>();
    const defaultProfileImage = 'https://nestcraft.sirv.com/Feelaxo/user.0d14c6999b4a5c8e86a7.png';

    useEffect(() => {
        if (businessId) {
            axios.get(`/api/business/get/staff/${businessId}`)
                .then((response) => {
                    const staffData: Staff[] = response.data || [];
                    const anystaff: Staff = {
                        id: 0,
                        name: 'Any Professional',
                        email: 'Select AnyStaff',
                        profile: defaultProfileImage,
                        job_title: '',
                        phone: 1234567890,
                        status: 1
                    };
                    setStaffList([anystaff, ...staffData]);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [businessId]);

    return (
        <div className="mx-8 pt-4">
            <h2 className="block text-left font-bold mb-10 pl-8 text-xl">Select Professional</h2>
            <div className="flex flex-wrap p-4 overflow-auto max-h-[90vh] pb-28">
                {staffList.map((staff) => (
                    <div key={staff.id} className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/4 p-2">
                        <div
                            onClick={() => { setSelectedStaffIndex(staff.id); setStaff(staff); }}
                            className={`flex flex-col cursor-pointer justify-between items-center h-48 border bg-white rounded-lg p-4 ${staff.id === selectedStaffIndex || selectedStaff?.id === staff.id ? "border-blue-500" : "border-gray-100"}`}
                        >
                            <img src={staff.profile || defaultProfileImage} alt={staff.name} className="w-20 h-20 rounded-full mb-4" />
                            <div className="text-center">
                                <p className="text-lg font-semibold">{staff.name}</p>
                                <p className="text-sm text-gray-600">{staff.job_title}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default StaffSelection;
