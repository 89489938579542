import { FC, useEffect, useState } from "react";
import { useMediaQuery } from 'react-responsive';
import { BusinessData, Service, ServiceType } from '../../types/types';
import DesktopImageGallery from "./components/DesktopImageGallery";
import MobileImageGallery from "./components/MobileImageGallery";
import BusinessDetails from "./components/businessDetails";
import SalonOpeningHours from "./components/opening_hours";
import ServiceDetails from "./components/selectService";
import Cart from "./components/cart";
import RatingComponent from "./components/Rating";
import AboutUs from "./components/AboutUs";
import StaffComponent from "./components/Staff";
import Review from "./components/Review";
import ListBusiness from "components/ListBusiness/ListBusiness";
import { Helmet } from 'react-helmet';
import axios from "axios";
import { Link } from "react-router-dom";
import GoogleReview from "./components/GoogleReview";


const StayDetailPageContainer: FC<{}> = () => {
  const [businessData, setBusinessData] = useState<BusinessData | null>(null);
  const [Services, setServices] = useState<ServiceType[]>([]);
  const [cartItems, setCartItems] = useState<Service[]>([]);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [isCartOpen, setIsCartOpen] = useState(false);
  const [address, setAddress] = useState("")

  const removeFromCart = (itemId: number) => {
    setCartItems((prevItems) => prevItems.filter((item) => item.id !== itemId));
  };

  const isServiceInCart = (service: Service): boolean => {
    return cartItems.some((item) => 
        item.id === service.id && 
        item.duration === service.duration && 
        item.price === service.price
    );
};

  const handleRemoveFromCart = (service: Service) => {
    setCartItems((prevItems) => prevItems.filter((item) => item.id !== service.id));
    removeFromCart(service.id);
    console.log(`Removed ${service.name} from cart`);
  };

  const handleAddToCart = (service: Service) => {
    const isServiceAlreadyInCart = cartItems.some((item) => item.id === service.id);
    if (!isServiceAlreadyInCart) {
      updateCart(service);
      console.log(`Added ${service.name} to cart`);
    } else {
      setCartItems((prevItems) => prevItems.filter((item) => item.id !== service.id));
      console.log(`Removed ${service.name} from cart`);
    }
  };

  const urlParams = new URLSearchParams(window.location.search);
  const id = urlParams.get("id");

  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isTablet = useMediaQuery({ maxWidth: 1024 });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("/api/business/post", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ id }),
        });

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const data: BusinessData = await response.json();
        setBusinessData(data);
        console.log("BusinessData status", data.status);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [id]);

  const updateCart = (service: Service) => {
    setCartItems((prevItems) => [...prevItems, service]);
  };

  const toggleCart = () => {
    setIsCartOpen((prevIsOpen) => !prevIsOpen);
  };


  function sumCartItemsPrices(cartItems: Service[]): number {
    let totalPrice = 0;
    for (const item of cartItems) {
      totalPrice += parseFloat(item.price);
    }
  
    return totalPrice;
  }


  const getBusinessType = (businessType: number) => {
    if (businessType == 1) {
      return "Spa"
    }
    if(businessType == 2) {
      return "Salon"
    }
    if(businessType == 3) {
      return "Wellness"
    }
  }

  const getBusinessCity =  (cityId: number) => {
    axios.post("/api/cities/id", {
      cityId
    }).then((response) => {
      setAddress(response.data)
    }).catch((error) => {
      console.log("error fetching cities")
      console.log(error)
    })
  }

  useEffect(() => {
    if(businessData != null) {
      getBusinessCity(businessData.city)
    }
  },[businessData])


  const getTitle = (type: number, name: string): string => {
    if (address !== "") {
      return `${name}, ${address} - Book Appointments Online`;
    } else {
      return `${name} - Book Appointments Online`;
    }
  };





  useEffect(() => {
    if(businessData) {
      axios.get(`/api/services/map/service/${businessData?.id}`).then((response) => {
        console.log(response.data)
        setServices(response.data)
      }).catch((error) => {
        console.error("Error fetching services: ", error)
      })
    }
    
  }, [businessData])

  const generateKeywords = (): string => {
    if (!businessData) return "";
  
    // Extract type, name, city, and services
    const type = getBusinessType(businessData.business_type) || "Business";
    const name = businessData.business_name || "Business Name";
    const city = address.split(",")[0]?.trim() || "City";
  
    // Create base keywords
    const baseKeywords = [
      type, name, city, 
      `${type} in ${city}`, `${type} services`,
      `${name} reviews`, `${name} ${city}`,
      `${name} ${type}`, `${type} ${city}`
    ];
  
    // Add location-based and service-based keywords
    const locationBased = [
      `${type} booking in ${city}`,
      `${name} appointment in ${city}`,
      `Book ${type} in ${city}`,
      `${type} services in ${city}`,
      `${type} near me`,
      `best ${type} in ${city}`,
      `top ${type} in ${city}`
    ];
  
    // High volume, low competition keywords
    const highVolumeKeywords = [
      "spa near me", "spa by me", "spa close to me", "spa near spa", "nearby spa",
      "best spa near me", "body spa near me", "spa services near me", "full body spa near me",
      "salon near me", "best salon near me"
    ];
  
    // Static keywords for SEO
    const staticKeywords = [
      "salon booking online", "spa booking online", "wellness booking online",
      "online booking platform", "beauty service booking", "personal care appointments",
      "hair salon booking", "massage booking online", "yoga class scheduling",
      "meditation session booking", "holistic wellness appointment", "fitness class scheduling"
    ];
  
    // Generate keywords for each service
    let serviceKeywords: string[] = [];
    Services.forEach(serviceType => {
      serviceType.services.forEach(service => {
        serviceKeywords.push(
          `${service.name} near me`,
          `${service.name} in ${city}`,
          `best ${service.name} in ${city}`,
          `top ${service.name} in ${city}`,
          `affordable ${service.name} near me`,
          `cheap ${service.name} in ${city}`,
          `professional ${service.name} near me`,
          `book ${service.name} online`,
          `${service.name} services near me`,
          `${service.name} appointment in ${city}`,
          `${service.name} deals in ${city}`,
          `${service.name} offers in ${city}`,
          `find ${service.name} near me`,
          `${service.name} pricing near me`,
          `local ${service.name} services`,
          `${service.name} expert near me`,
          `${service.name} treatment near me`,
          `quick ${service.name} in ${city}`
        );
      });
    });
  
    // Combine all keywords, filter out empty and duplicate ones
    const allKeywords = [...baseKeywords, ...locationBased, ...highVolumeKeywords, ...staticKeywords, ...serviceKeywords]
      .filter((keyword, index, self) => keyword && self.indexOf(keyword) === index);
  
    return allKeywords.join(", ");
  };


  const spaSearches = [
    "massage near me",
    "spa massage near me",
    "hair spa near me",
    "men spa near me",
    "spa near me",
    "Kerala massage near me",
    "body spa close to me",
    "thai spa near me ",
    "massage close to me",
    "wellness spa",
    "spa treatments",
    "facial spa",
    "day spa",
    "luxury spa",
    "couples spa",
    "hot stone massage",
    "aromatherapy spa",
    "foot spa",
    "organic spa",
    "spa packages",
    "home spa treatments",
    "resort spa",
    "spas and salons",
    "deep tissue massage",
    "detox spa",
    "medical spa",
    "facial treatments"
  ];

  
  const salonSearches = [
    "beauty salon near me",
    "hair",
    "hairstyle",
    "unisex salon",
    "nail salon",
    "beautician",
    "bridal makeup",
    "hair color",
    "men's salon",
    "makeup artist",
    "hair extensions",
    "hair cut near me",
    "blowout salon",
    "eyebrow threading",
    "waxing salon",
    "salon near me",
    "salon services",
    "hair treatments",
    "salon appointment",
    "barber shop",
    "hair styling products",
    "salon reviews",
    "beauty services",
    "salon offers",
    "spa salon"
  ];
  


  const wellnessSearches = [
    "wellness",
    "health",
    "wellness forever",
    "spa near me",
    "himalaya wellness",
    "nutrition",
    "mental wellness",
    "holistic health",
    "alternative medicine",
    "wellness programs",
    "herbal supplements",
    "wellness center",
    "wellness retreat",
    "wellness coach",
    "wellness tips",
    "wellness workshops",
    "wellness products",
    "wellness activities",
    "wellness spa retreat",
    "corporate wellness",
    "wellness tourism"
  ];
    
  


    const servicesLink = businessData?.business_type === 1 ? spaSearches :
                 businessData?.business_type === 2 ? salonSearches :
                 wellnessSearches;



  return (
    <>
      {businessData && (
        <>

          <Helmet>
            <title>{getTitle(businessData.business_type, businessData.business_name)}</title>
            <meta name="description" content={businessData.description.substring(0, 320)} />
            <meta name="keywords" content={generateKeywords()} />
          </Helmet>

        
        <div className="ListingDetailPage">
          <div className="container ListingDetailPage__content" >
            <div className="nc-ListingStayDetailPage">
              {isMobile ?  (
                <>
                  {/*show the mobile image gallery */}
                  <MobileImageGallery  business_name={businessData.business_name} city={businessData.address} business_id={businessData.id} business_type={businessData.business_type} imageUrls={businessData.gallery} />
                  {/*show the business detail */}
                  <div className="mt-64 sm:mt-[68%]">
                    <BusinessDetails businessDetails={businessData} />
                  </div>
                  
                </>
              ) : isTablet ? (
                <>
                  <div className="mt-4 mb-4">
                    <BusinessDetails businessDetails={businessData} />
                  </div>
                  <DesktopImageGallery business_name={businessData.business_name} city={businessData.address} thumbnail={businessData.thumbnail} business_type={businessData.business_type} imageUrls={businessData.gallery} />
                </>
              ) : (
                <>
                  <div className="mt-4 mb-4">
                    <BusinessDetails businessDetails={businessData} />
                  </div>
                  <DesktopImageGallery  business_name={businessData.business_name} city={businessData.address}  thumbnail={businessData.thumbnail} business_type={businessData.business_type} imageUrls={businessData.gallery} />
                </>
              )}
              <main className="relative z-40 lg:mt-8 flex flex-col lg:flex-row">
                <div className="w-full g:w-3/5 xl:w-2/3 space-y-8 lg:space-y-10 mb-8 md:mt-0 lg:mt-0 lg:pr-10">
                  <ServiceDetails handleAddToCart={handleAddToCart} isServiceInCart={isServiceInCart} business_id={businessData.id} business_name={businessData.business_name} rating={businessData.rating} updateCart={updateCart} removeFromCart={removeFromCart} />
                <StaffComponent business_name={businessData.business_name} business_id={businessData.id} /> 
                  <AboutUs businessData={businessData} />
                  <SalonOpeningHours business_name={businessData.business_name} business_id={businessData.user_id} status={businessData.status} />
                  <div className='listingSection__wrap'>
                    <h2 className="font-bold text-xl">Customer Ratings and Reviews</h2>
                    <Review business_id={businessData.id} total_review={businessData.total_rating} rating={businessData.rating} />
                    <GoogleReview business_id={businessData.id} />
                    <RatingComponent business_id={businessData.id} />
                  </div>
                  <br />
                  {businessData.youtube_link && (
                    <>
                      <h2 className="font-bold text-xl">Video Introduction</h2>
                      <div className="my-8">
                        <div className="aspect-w-16 aspect-h-9">
                          <iframe
                            className="rounded-lg"
                            src={`${businessData.youtube_link}`}
                            title="YouTube video player"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                          ></iframe>
                        </div>
                      </div>
                      <br />
                    </>
                  )}
                  {/* popup */}
                  {businessData.claimed == 0 && (
                    <span className="text-lg p-3 mt-5">Own This Business ?
                      <span className="  cursor-pointer text-l underline rounded-md p-4  mb-8 text-center"
                        onClick={() => setIsFormOpen(true)}>Claim Now</span></span>
                  )}
                  {
                    isFormOpen && (
                      <ListBusiness business_id={businessData.id} business_name={businessData.business_name} setIsFormOpen={setIsFormOpen} />
                    )
                  }

<div className="container-lg text-left mb-20">
          {
            Services && (
              <>
                <h3 className="my-4 font-bold text-lg">Popular {getBusinessType(businessData.business_type)} services searched by customers at {address}</h3>
                {Services.flatMap(serviceType => serviceType.services)
                  .slice(0, 15)
                  .map(service => (
                    <Link className="underline text-xs mr-4" to={`/listing?query=${service.name}`} title={`${service.name} at ${businessData.business_name} in ${address}`} key={service.name}>
                      {service.name}
                    </Link>
                  ))
                }
              </>
            )
          }
          {
          servicesLink.map(service => (
              <Link
                className="underline mr-4 text-xs"
                to={`/listing?query=${service}`}
                title={`${service} at ${businessData.business_name} in ${address}`}
                key={service}
              >
                {service}
              </Link>
          ))}
          </div>


                </div>
                
                    {(isMobile || isTablet) ? (

                      <>

                      {
                        isCartOpen && (
                          <div onClick={toggleCart} className="bg-black opacity-50 w-full h-full fixed z-50 top-0 left-0"></div>
                        )
                      }


                        <div className="fixed justify-center w-[100%] left-0 bottom-0 z-50 rounded-full">
                        {isCartOpen && (
                          <>
                          
                            {/* <div className=" bg-white w-full h-full absolute"></div> */}
                              <div className="container bg-white mx-auto w-full grid p-4 border border-gray-300">
                              {/* Pass business_id to Cart component */}
                              <div onClick={toggleCart} className="w-full flex justify-end text-3xl">×</div>
                              <Cart business_id={businessData.id} business_name={businessData.business_name} rating={businessData.rating} cartItems={cartItems} updateCart={updateCart} className="pb-8" removeFromCart={handleRemoveFromCart} />
                            </div>
                            </>
                          )}

                          <div onClick={toggleCart} className="z-40 container bg-white border  w-full h-auto flex items-center justify-between px-4 py-2 rounded-lg">
                              <div className="flex flex-col">
                                  <span className="text-gray-800 font-semibold">Cart Total: ₹{sumCartItemsPrices(cartItems)}</span>
                                  <span className="text-gray-500">Total Items: {cartItems.length}</span>
                              </div>
                              <button className="bg-red-400 text-white px-4 py-2 rounded-full hover:bg-red-600 transition duration-300">Cart</button>
                          </div>
                        </div>  
                      </>


                    ) : (
                      <>
                      <div className="flex-grow mt-0 w-96" >
                        <div className="sticky top-28 ">
                          <div className="listingSectionSidebar__wrap mt-6 flex justify-center" style={{ backgroundColor: 'white', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)' }}>
                            <Cart business_id={businessData.id} business_name={businessData.business_name} rating={businessData.rating} cartItems={cartItems} updateCart={updateCart} removeFromCart={handleRemoveFromCart} />
                          </div>
                        </div>
                      </div>
                      </>
                    )}
              </main>
            </div>
          </div>
          
        </div>

        </>
      )}

    </>
  );
};

export default StayDetailPageContainer;
