import React, { useState } from "react";
import axios from "axios";

interface ListBusinessProps {
  setIsFormOpen: (isOpen: boolean) => void;
  business_name: string;
  business_id: number;
}

const ListBusiness: React.FC<ListBusinessProps> = ({ setIsFormOpen, business_name, business_id }) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    address: "",
    businessProof: null as File | null,
  });
  const [isRequesting, setIsRequesting] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [errors, setErrors] = useState({
    email: "",
    phone: ""
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));

    if (name === "email") {
      validateEmail(value);
    }

    if (name === "phone") {
      validatePhone(value);
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length) {
      const file = e.target.files[0];
      setFormData((prevFormData) => ({
        ...prevFormData,
        businessProof: file, // Store the file object
      }));
    }
  };

  const validateEmail = (email: string) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setErrors((prevErrors) => ({
      ...prevErrors,
      email: emailPattern.test(email) ? "" : "Invalid email address"
    }));
  };

  const validatePhone = (phone: string) => {
    const phonePattern = /^\d{10}$/; // Example pattern for a 10-digit phone number
    setErrors((prevErrors) => ({
      ...prevErrors,
      phone: phonePattern.test(phone) ? "" : "Invalid phone number"
    }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsRequesting(true);

    // Validate all fields before submitting
    if (!formData.email || !formData.phone || errors.email || errors.phone) {
      setIsRequesting(false);
      return;
    }

    // Create form data object
    const formDataToSend = new FormData();
    formDataToSend.append("name", formData.name);
    formDataToSend.append("email", formData.email);
    formDataToSend.append("phone", formData.phone);
    formDataToSend.append("address", formData.address);

    formDataToSend.append("business_name", business_name);
    formDataToSend.append("business_id", business_id.toString());

    // Append file data with the file extension
    if (formData.businessProof) {
      formDataToSend.append("businessProof", formData.businessProof);
    }

    try {
      const response = await axios.post("/api/admin/business/claim", formDataToSend);
      console.log(response.data);
      setShowSuccess(true);
    } catch (error) {
      console.error("Error submitting form:", error);
    } finally {
      setIsRequesting(false);
    }
  };

  // Check if any of the fields are empty or have validation errors
  const isAnyFieldEmpty = Object.values(formData).some((value) => value === "");
  const hasValidationErrors = Object.values(errors).some((error) => error !== "");

  return (
    <>
    <div className="fixed inset-0 flex items-center justify-center z-40">
      <div className="absolute inset-0 bg-black opacity-50"></div>
      <div className="bg-white p-8 rounded-lg shadow-lg z-40 relative">
        <div className="flex justify-between items-center">
        <div><h2 className="text-xl font-bold mb-4">Claim This Business</h2></div>
        <div><button className="text-4xl font-thin text-black" onClick={() => setIsFormOpen(false)}>&times;</button></div>
        </div>
        <form onSubmit={handleSubmit}>
            <h2 className="font-semibold text-xl"> {business_name}</h2><br />
            <div className="mb-4">
              <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                Name
              </label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
              />
            </div>
            <div className="mb-4">
              <label htmlFor="phone" className="block text-sm font-medium text-gray-700">
                Phone Number
              </label>
              <input
                type="tel"
                id="phone"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
              />
              {errors.phone && <p className="text-red-500 text-sm mt-1">{errors.phone}</p>}
            </div>
            <div className="mb-4">
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                Email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
              />
              {errors.email && <p className="text-red-500 text-sm mt-1">{errors.email}</p>}
            </div>
            
            <div className="mb-4">
              <label htmlFor="address" className="block text-sm font-medium text-gray-700">
                Address
              </label>
              <textarea
                id="address"
                name="address"
                value={formData.address}
                onChange={handleChange}
                className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
              />
            </div>
            <div className="mb-4">
              <label htmlFor="businessProof" className="block text-sm font-medium text-gray-700">
                Business Proof
              </label>
              <input
                type="file"
                id="businessProof"
                name="businessProof"
                onChange={handleFileChange}
                className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
              />
              <p className="text-xs text-gray-500">Upload any of the business documents (Gst, Shop Licence, Udyam etc.)</p>
            </div>
            <div className="text-center">
              <button
                type="submit"
                className={`inline-flex justify-center py-3 px-6 w-40 border border-transparent shadow-sm text-sm font-medium rounded-md text-white ${isRequesting || isAnyFieldEmpty || hasValidationErrors ? 'bg-red-200 cursor-not-allowed' : 'cursor-pointer bg-red-500'}`}
                disabled={isRequesting || isAnyFieldEmpty || hasValidationErrors} // Disable if requesting, any field is empty, or has validation errors
              >
                {isRequesting ? 'Submitting...' : 'Submit'}
              </button>
            </div>
          </form>
      </div>
    </div>
    {showSuccess && (
      <div className="fixed z-50 inset-0 flex items-center justify-center bg-gray-500 bg-opacity-50">
      <div className="bg-white w-80 p-6 rounded shadow-md">
        <div className="flex items-center justify-center">
          <svg className="w-12 h-12 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path>
          </svg>
        </div>
        <div className="mt-4 text-center">
          <p className="text-lg font-semibold text-gray-800">Success!</p>
          <p className="text-gray-600">Business claim request sent successfully! We will contact you in 2 business days.</p>
        </div>
        <div className="mt-6 flex justify-center">
          <button className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 focus:outline-none focus:bg-green-600" onClick={() => {setShowSuccess(false); setIsFormOpen(false)}}>OK</button>
        </div>
      </div>
    </div>
    )}
    </>
  );
};

export default ListBusiness;
