import React, { FC, useState,useEffect } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Input from "shared/Input/Input";
import VerifyOtp from "containers/verifyOtp/VerifyOtp";

// Import multiple images
import loginImage1 from "./feelaxo-4.jpg";
import loginImage2 from "./feelaxo-7.jpg";
import loginImage3 from "./feelaxo-13.jpg";
import loginImage4 from "./feelaxo-6.jpg"




const PageLogin: FC = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [otpVerification, setOtpVerification] = useState(true);
  const [userId, setUserId] = useState<number | null>(null);
  const [randomImage, setRandomImage] = useState<string>("");


  // Array of images
  const images = [loginImage1,loginImage2,loginImage3,loginImage4];
  useEffect(() => {
    // Select a random image on component mount
    setRandomImage(images[Math.floor(Math.random() * images.length)]);
  }, []);
  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setEmailError("");
    setPasswordError("");

    const loginData = {
      email,
      password,
    };

    try {
      const response = await fetch("api/customer/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(loginData),
      });

      if (response.ok) {
        const responseData = await response.json();
        if (responseData.message === "001") {
          setEmailError("Email not found");
        }

        if (responseData.message === "002") {
          setPasswordError("Incorrect password");
        }
        if (responseData.message === "303") {
          setUserId(responseData.userId);
          setOtpVerification(true);
        }

        if (responseData.message === "200") {
          const expiryDate = new Date();
          expiryDate.setDate(expiryDate.getDate() + 10);
          document.cookie = `userId=${responseData.userId}; expires=${expiryDate.toUTCString()}; path=/`;

          const cookies = document.cookie.split(";");
          const onBookingCookie = cookies.find((cookie) =>
            cookie.trim().startsWith("onBooking=")
          );

          if (onBookingCookie) {
            const onBookingValue = onBookingCookie.split("=")[1].trim();
            if (onBookingValue === "true") {
              window.location.href = "/booking";
            } else {
              window.location.href = "/dashboard";
            }
          } else {
            window.location.href = "/dashboard";
          }
        }
      } else {
        const errorData = await response.json();
      }
    } catch (error) {
      alert("Incorrect username or password");
    }
  };

  return (
    <>
      <div className="nc-PageLogin mb-6" data-nc-id="PageLogin">
      <Helmet>
          <title>Feelaxo - Log into your account</title>
          <meta name="description" content="Log into your Feelaxo account to access beauty and wellness services. Enter your email and password to get started." />
          <meta name="keywords" content="Feelaxo login, beauty services login, wellness services login, user login, customer login" />
        </Helmet>
        <div className="flex flex-col md:flex-row">
          <div className="w-full md:w-1/2 bg-gray-100 md:bg-transparent hidden md:block">
            <img
              src={randomImage}
              alt="Login Image"
              className="w-full h-auto md:h-full"
            />
          </div>
          <div className="w-full md:w-1/2 px-4 py-8 md:py-20 flex flex-col justify-center items-center">
            <h1 className="font-semibold text-3xl text-center mb-10">
              Already a user? Login now
            </h1>
            <form className="grid grid-cols-1 gap-6 w-full max-w-sm" onSubmit={handleSubmit}>
              <label className="block">
                <span className="text-neutral-800 dark:text-neutral-200">
                  Enter phone or email
                </span>
                <Input
                  type="text"
                  placeholder="enter phone or email"
                  className={`mt-1 ${emailError !== "" ? "border-red-500" : ""}`}
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <p className="text-red-500">
                  {emailError !== "" ? "Email not found" : ""}
                </p>
              </label>

              <label className="block">
                <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                  Password
                  <Link to="/forgot-pass" className="text-sm">
                    Forgot password?
                  </Link>
                </span>
                <Input
                  type="password"
                  placeholder="Password"
                  className={`mt-1 ${passwordError !== "" ? "border-red-500" : ""}`}
                  required
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <p className="text-red-500">
                  {passwordError !== "" ? "Incorrect password" : ""}
                </p>
              </label>
              <button type="submit" className="w-full bg-black w-full rounded-full py-3 text-white hover:bg-red-500">
                Continue
              </button>
            </form>

            <div className="text-center mt-4 md:text-left">
              <span className="block text-neutral-700 dark:text-neutral-300">
               
                <Link to="/signup" className="text-blue-500">
                <button type="submit" className="w-96 border border-black rounded-full py-3 my-4 text-black hover:bg-red-500 hover:text-white">
                New user? Create an account
              </button>
                </Link>
              </span>

              <span className="block text-center  dark:text-red-300">
          <Link to="https://pos.feelaxo.com/admin" target="_blank" className="text-black underline">
            Business Login
          </Link>
        </span>

            </div>
          </div>
        </div>
      </div>

      {otpVerification && userId != null && <VerifyOtp user_id={userId} />}
    </>
  );
};

export default PageLogin;
