import React, { useEffect, useState } from "react";
import axios from "axios";
import JsPDF from "jspdf";
import html2canvas from "html2canvas";
import { saveAs } from "file-saver";

interface InvoiceProps {
  htmlString: string;
  phone: string;
  otp: string;
  paymentId: string;
}

const Invoice: React.FC<InvoiceProps> = ({ htmlString, phone, otp, paymentId }) => {
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);
  const [pdfUrl, setPdfUrl] = useState("#");

  const htmlStringToPdf = async (html: string) => {
    try {
      const iframe = document.createElement("iframe");
      iframe.style.visibility = "hidden";
      document.body.appendChild(iframe);
      const iframedoc = iframe.contentDocument || iframe.contentWindow?.document;

      if (!iframedoc) {
        throw new Error("Unable to access iframe document");
      }

      iframedoc.body.innerHTML = html;

      const scale = 2;
      const canvas = await html2canvas(iframedoc.body, { scale });

      const imgData = canvas.toDataURL("image/png");

      const doc = new JsPDF({
        format: "a4",
        unit: "mm",
      });
      doc.addImage(imgData, "PNG", 0, 0, 210, 297);

      const blob = doc.output("blob");

      const formData = new FormData();
      formData.append("pdf", blob, `${paymentId}_invoice.pdf`);

      setLoading(true);

      const response = await axios.post("/api/order/confirm", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        params: {
          paymentId,
          phone,
          otp,
        },
      });

      console.log("PDF created and data sent to backend successfully!");

      setLoading(false);
      setSuccess(true);
      setPdfUrl(response.data.s3PdfUrl);  // Store the PDF URL from the backend

    } catch (error) {
      setLoading(false);
      console.error("Error creating PDF or sending data to backend:", error);
    }
  };

  useEffect(() => {
    // Call the function when the component mounts
    htmlStringToPdf(htmlString);
  }, [htmlString, phone, otp, paymentId]);

  return (
    <>
       {loading && !success && (
        <div>
        <div className="fixed top-0 left-0 w-full h-full bg-white bg-opacity-90" style={{ zIndex: 45 }} />
        <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50 text-center p-4 rounded-lg">
        <div className="animate-spin rounded-full h-24 w-24 border-t-2 border-b-2 border-gray-900"> </div>
        <p className="drop-shadow-lg mt-5">Please wait</p>
        </div> 
      </div> 
      )}

      {success && (
        <div>
          <div className="fixed top-0 left-0 w-full h-full bg-white" style={{ zIndex: 50 }} />
          <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50 w-80 shadow-xl text-center p-4 rounded-lg border">
            <h1 className="font-bold text-xl text-green-800">Order successfully placed!</h1><br />
            <a href={pdfUrl} download={`${paymentId}_invoice.pdf`} style={{ color: 'black', margin:'10px', textDecoration: 'underline', cursor: 'pointer' }}>
              Download Invoice
            </a><br />

            <a href="/orders">
              <button className="bg-black w-46 text-white px-4 py-2 rounded-md mt-4">
                View Order
              </button>
            </a>
          </div>
        </div>
      )} 
    </>
  );
};

export default Invoice;
