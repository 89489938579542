import React, { useEffect, useState, useRef } from "react";
import { Helmet } from "react-helmet";
import userImage from "./user.png";
import axios from "axios";
import { UserData } from "../../types/types";

const AccountPage: React.FC = () => {
  const [userData, setUserData] = useState<UserData | null>(null);
  const [editablePhone, setEditablePhone] = useState(false);
  const [editableName, setEditableName] = useState(false);
  const [editableEmail, setEditableEmail] = useState(false);
  const [newName, setNewName] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [newPhone, setNewPhone] = useState("");
  const nameInputRef = useRef<HTMLInputElement>(null);
  const emailInputRef = useRef<HTMLInputElement>(null);
  const phoneInputRef = useRef<HTMLInputElement>(null);

  const readCookie = (cookieName: string): string | null => {
    const nameEQ = cookieName + "=";
    const cookies = document.cookie.split(";");

    for (let i = 0; i < cookies.length; i++) {
      let cookie = cookies[i];
      while (cookie.charAt(0) === " ") {
        cookie = cookie.substring(1, cookie.length);
      }
      if (cookie.indexOf(nameEQ) === 0) {
        return cookie.substring(nameEQ.length, cookie.length);
      }
    }
    return null;
  };

  const userId = readCookie("userId");

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.post("/api/customer/post/customer", {
          userId: userId,
        });
        setUserData(response.data);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    if (userId) {
      fetchUserData();
    }
  }, [userId]);

  // useEffect(() => {
  //   const handleClickOutside = (event: MouseEvent) => {
  //     if (
  //       nameInputRef.current &&
  //       !nameInputRef.current.contains(event.target as Node)
  //     ) {
  //       setEditableName(false);
  //     }
  //     if (
  //       emailInputRef.current &&
  //       !emailInputRef.current.contains(event.target as Node)
  //     ) {
  //       setEditableEmail(false);
  //     }
  //     if (
  //       phoneInputRef.current &&
  //       !phoneInputRef.current.contains(event.target as Node)
  //     ) {
  //       setEditablePhone(false);
  //     }
  //   };

  //   document.addEventListener("mousedown", handleClickOutside);
  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, []);

  const handleEditPhone = () => {
    setEditablePhone(true);
    setNewPhone(userData?.phone || "");
  };


  const handleCancelPhone = () => {
    setEditablePhone(false);
  };

  const handleEditName = () => {
    setEditableName(true);
    setNewName(userData?.name || "");
  };

  const handleCancelName = () => {
    setEditableName(false);
  };

  const handleEditEmail = () => {
    setEditableEmail(true);
    setNewEmail(userData?.email || "");
  };


  const handleCancelEmail = () => {
    setEditableEmail(false);
  };


  const handleSaveName = async () => {
    try {
      await axios.post('/api/customer/edit', {
        userId,
        name: newName,
      });
      setUserData({ ...userData, name: newName } as UserData);
      setEditableName(false);
    } catch (error) {
      console.error('Error updating name:', error);
    }
  };
  
  const handleSaveEmail = async () => {
    console.log("email click")
    try {
      await axios.post('/api/customer/edit', {
        userId,
        email: newEmail,
      });
      setUserData({ ...userData, email: newEmail } as UserData);
      setEditableEmail(false);
    } catch (error) {
      console.error('Error updating email:', error);
    }
  };
  
  const handleSavePhone = async () => {
    try {
      await axios.post('/api/customer/edit', {
        userId,
        phone: newPhone,
      });
      setUserData({ ...userData, phone: newPhone } as UserData);
      setEditablePhone(false);
    } catch (error) {
      console.error('Error updating phone:', error);
    }
  };

  return (
    <>
      <Helmet>
        <title>Feelaxo - User Profile</title>
      </Helmet>

      <div className="nc-AccountPage bg-gray-100 p-4">
        <div className="flex justify-center items-center h-full mt-10 mb-10">
          <div className="grid gap-8 bg-white p-8 rounded-lg shadow-md text-center items-center max-w-md mx-auto">
            <div className="flex items-center justify-center">
              <img
                src={userImage}
                alt="User"
                className="rounded-full w-24 h-24"
              />
            </div>

            <div className="flex flex-col justify-center text-center">
              {userData && (
                <div className="block mb-4">
                  <h2 className="text-2xl font-semibold mb-1">
                    {editableName ? (
                      <input
                        type="text"
                        value={newName}
                        onChange={(e) => setNewName(e.target.value)}
                        ref={nameInputRef}
                        className="border-gray-300 border rounded-md w-full px-2 py-1"
                      />
                    ) : (
                      <>
                        {userData.name}
                        <button onClick={handleEditName} className="ml-2">
                          <svg
                            className="w-5 text-black"
                            viewBox="0 0 24 24"
                            strokeWidth="2"
                            stroke="currentColor"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <path stroke="none" d="M0 0h24v24H0z" />
                            <path d="M9 7 h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3" />
                            <path d="M9 15h3l8.5 -8.5a1.5 1.5 0 0 0 -3 -3l-8.5 8.5v3" />
                            <line x1="16" y1="5" x2="19" y2="8" />
                          </svg>
                        </button>
                      </>
                    )}
                  </h2>
                  {editableEmail ? (
                    <input
                      type="text"
                      value={newEmail}
                      onChange={(e) => setNewEmail(e.target.value)}
                      ref={emailInputRef}
                      className="border-gray-300 border rounded-md w-full px-2 py-1 mt-2"
                    />
                  ) : (
                    <p className="text-gray-600">
                      {userData.email}
                      <button onClick={handleEditEmail} className="ml-2">
                        <svg
                          className="w-5 text-black"
                          viewBox="0 0 24 24"
                          strokeWidth="2"
                          stroke="currentColor"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" />
                          <path d="M9 7 h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3" />
                          <path d="M9 15h3l8.5 -8.5a1.5 1.5 0 0 0 -3 -3l-8.5 8.5v3" />
                          <line x1="16" y1="5" x2="19" y2="8" />
                        </svg>
                      </button>
                    </p>
                  )}
                  {editablePhone ? (
                    <input
                      type="text"
                      value={newPhone}
                      onChange={(e) => setNewPhone(e.target.value)}
                      ref={phoneInputRef}
                      className="border-gray-300 border px-2 py-1 rounded-md w-full mt-2"
                    />
                  ) : (
                    <p className="text-gray-600">
                      {userData.phone}
                      <button onClick={handleEditPhone} className="ml-2">
                        <svg
                          className="w-5 text-black"
                          viewBox="0 0 24 24"
                          strokeWidth="2"
                          stroke="currentColor"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" />
                          <path d="M9 7 h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3" />
                          <path d="M9 15h3l8.5 -8.5a1.5 1.5 0 0 0 -3 -3l-8.5 8.5v3" />
                          <line x1="16" y1="5" x2="19" y2="8" />
                        </svg>
                      </button>
                    </p>
                  )}
                </div>
              )}

              <div className="flex justify-center md:justify-start space-x-2">
                {editablePhone ? (
                  <>
                    <button
                      className="bg-black text-white px-4 py-2 rounded-full"
                      onClick={handleSavePhone}
                    >
                      Save
                    </button>
                    <button
                      className="bg-gray-300 text-black px-4 py-2 rounded-full"
                      onClick={handleCancelPhone}
                    >
                      Cancel
                    </button>
                  </>
                ) : null}
              </div>

              <div className="flex justify-center md:justify-start space-x-2">
                {editableName ? (
                  <>
                    <button
                      className="bg-black text-white px-4 py-2 rounded-full"
                      onClick={handleSaveName}
                    >
                      Save
                    </button>
                    <button
                      className="bg-gray-300 text-black px-4 py-2 rounded-full"
                      onClick={handleCancelName}
                    >
                      Cancel
                    </button>
                  </>
                ) : null}
              </div>

              <div className="flex justify-center md:justify-start space-x-2">
                {editableEmail ? (
                  <>
                    <button
                      className="bg-black text-white px-4 py-2 rounded-full"
                      onClick={handleSaveEmail}
                    >
                      Save
                    </button>
                    <button
                      className="bg-gray-300 text-black px-4 py-2 rounded-full"
                      onClick={handleCancelEmail}
                    >
                      Cancel
                    </button>
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AccountPage;
