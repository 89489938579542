import React, { useState, useEffect } from "react";
import NavigationItem from "./NavigationItem";
import { NAVIGATION_DEMO } from "data/navigation";

function Navigation() {
  const [refreshKey, setRefreshKey] = useState(0);

  useEffect(() => {
    setRefreshKey((prevKey) => prevKey + 1);
  }, []);

  return (
    <ul className="nc-Navigation justify-center hidden md:flex md:flex-wrap md:items-center md:space-x-1 relative">
      {NAVIGATION_DEMO.map((item) => (
        <NavigationItem key={item.id + refreshKey} menuItem={item} />
      ))}
    </ul>
  );
}

export default Navigation;
