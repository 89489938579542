import React from "react";
import HeroSearchForm2Mobile from "./HeroSearchForm2Mobile";
import { PathName } from "routers/types";
import { useLocation } from "react-router-dom";


const HeroSearchForm2MobileFactory = () => {
  return <HeroSearchForm2Mobile />;
};

export default HeroSearchForm2MobileFactory;