import React, { useState, useEffect } from "react";
import axios from "axios";

interface VerifyOtpProps {
  user_id: number;
}

const VerifyOtp: React.FC<VerifyOtpProps> = ({ user_id }) => {
  const [otpSentCount, setOtpSentCount] = useState(0);
  const [timer, setTimer] = useState(120); // 2 minutes in seconds
  const [enteredOtp, setEnteredOtp] = useState("");

  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false)

  useEffect(() => {
    sendOtp(); // Send OTP when component mounts
    setTimer(120); // Reset the timer

    const intervalId = setInterval(() => {
      setTimer((prevTimer) => (prevTimer > 0 ? prevTimer - 1 : 0)); // Decrement timer every second
    }, 1000);

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, [otpSentCount]);

  const sendOtp = () => {
    axios
      .post("/api/customer/post/verify", {
        userId: user_id,
      })
      .then((response) => {
        console.log(response.data);
        
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleResendClick = () => {
    if (timer === 0) {
      setOtpSentCount((prevCount) => prevCount + 1);
      sendOtp(); // Resend OTP when clicked
    } else {
      console.log("Please wait for the timer to expire before resending.");
    }
  };

  const handleConfirmClick = () => {
    // Make a request to verify the entered OTP
    axios
      .post("/api/customer/post/verify/otp", {
        userId: user_id,
        enteredOtp: enteredOtp,
      })
      .then((response) => {
        console.log(response.data);
        if(response.data.message == '002') {
            setError(true)
        }
        if (response.data.message === '200') {
            setSuccess(true);
          
            // Set userId cookie
            const expiryDate = new Date();
            expiryDate.setDate(expiryDate.getDate() + 10);
            document.cookie = `userId=${user_id}; expires=${expiryDate.toUTCString()}; path=/`;
          
            // Check for onBooking cookie
            const cookies = document.cookie.split(';');
            const onBookingCookie = cookies.find(cookie => cookie.trim().startsWith('onBooking='));
          
            if (onBookingCookie) {
              const onBookingValue = onBookingCookie.split('=')[1].trim();
          
              // Redirect based on the value of onBooking cookie
              if (onBookingValue === 'true') {
                window.location.href = '/booking';
              } else {
                window.location.href = '/dashboard';
              }
            } else {
              // Redirect to a default location if onBooking cookie is not present
              window.location.href = '/dashboard';
            }
          }
        
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${String(minutes).padStart(2, "0")}:${String(remainingSeconds).padStart(2, "0")}`;
  };

  return (
    <>
      <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-90" style={{ zIndex: 45 }} />
      <div className="w-80 fixed top-1/2 left-1/2 transform bg-white -translate-x-1/2 -translate-y-1/2 z-50 text-center p-4 rounded-lg">
        <h1 className="mb-2 text-lg font-semibold">Verify Phone number</h1>
        <div className="mb-4">
          We have sent a 6 digit OTP on your WhatsApp number. Please enter the OTP to confirm your phone number.
        </div>
        {
            error && (
                <div className="text-red-500 text-center">Please enter correct OTP</div>
            )
        }
        {
            success && (
                <div className="text-green-500 text-center">Phone number verified!</div>
            )
        }
        <input
          className="rounded-md w-72"
          placeholder="Enter OTP"
          value={enteredOtp}
          onChange={(e) => setEnteredOtp(e.target.value)}
        />
        <button
          className="w-72 bg-black text-white mt-4 p-2 rounded-md hover:opacity-80"
          onClick={handleConfirmClick}
        >
          Confirm
        </button>
        <button
          onClick={handleResendClick}
          className={`w-72 mt-2 p-2 rounded-md text-blue-500 hover:opacity-80 ${
            timer > 0 ? "opacity-50 cursor-not-allowed" : ""
          }`}
          disabled={timer > 0}
        >
          Resend OTP {timer > 0 ? `(${formatTime(timer)})` : ""}
        </button>
      </div>
    </>
  );
};

export default VerifyOtp;
