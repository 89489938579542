import React, { useEffect, useState } from 'react';
import './style.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar, faStarHalfAlt, faShare } from "@fortawesome/free-solid-svg-icons";
import { Service } from "../../../types/types";

interface CartProps {
  business_id: number;
  cartItems: Service[];
  business_name: string;
  rating: number,
  updateCart: (service: Service) => void;
  removeFromCart: (service: Service) => void;
  className?: string;
}

const Cart: React.FC<CartProps> = ({ business_id, business_name, rating, cartItems, updateCart, removeFromCart, className }) => {
  const [userIdExists, setUserIdExists] = useState<boolean>(false);

  useEffect(() => {
    const getUserIdFromCookie = () => {
      return document.cookie.includes('userId');
    };

    setUserIdExists(getUserIdFromCookie());
  }, []);

  const renderStars = (rating: number) => {
    const stars = [];
    const roundedRating = Math.floor(rating);

    // Filled stars
    for (let i = 0; i < roundedRating; i++) {
      stars.push(
        <FontAwesomeIcon
          key={i}
          icon={faStar}
          className="text-black text-sm"
        />
      );
    }

    // Half star for fractional ratings
    if (roundedRating < rating) {
      stars.push(
        <FontAwesomeIcon
          key="half-star"
          icon={faStarHalfAlt}
          className="text-black text-sm"
        />
      );
    }

    // Empty stars
    for (let i = stars.length; i < 5; i++) {
      stars.push(
        <FontAwesomeIcon
          key={`empty-star-${i}`}
          icon={faStar}
          className="text-black text-sm opacity-10"
        />
      );
    }

    return <span className="ml-1">{stars}</span>;
  };


  const setCookie = () => {
    const expireDate = new Date();
    expireDate.setTime(expireDate.getTime() + 3 * 60 * 60 * 1000); // 3 hours
    document.cookie = `onBooking=true; expires=${expireDate.toUTCString()}; path=/`;
  };

  const calculateTotal = () => {
    return cartItems.reduce((total, item) => total + parseFloat(item.price), 0);
  };

  const handleBookNow = () => {
    if (userIdExists) {
      localStorage.setItem('cartData', JSON.stringify({ business_id, cartItems }));
      window.location.href = '/booking';
    } else {
      localStorage.setItem('cartData', JSON.stringify({ business_id, cartItems }));
      setCookie();
      window.location.href = '/login';
    }
  };

  return (
    <div className={`w-[100%] ${className}`}>
      <h1 className='text-xl flex sm:text-xl lg:text-xl font-bold mb-4'> {business_name} </h1>
      <p className={`text-black font-bold `} style={{ marginTop: '-15px' }}>{renderStars(rating)}</p>
      <h3 className="text-l sm:text-l lg:text-l font-semibold mb-4">Book Appointment</h3>
      {cartItems.length > 0 ? (
        <>
          <div className="cartItem">
            <div className="font-semibold">Service</div>
            <div className="font-semibold">Price(₹)</div>
           
            <button className=""></button>
          </div>

          {cartItems.map((item) => (
            <div key={item.id}>
              <div className="cartItem">
                <div>{item.name}<span className='text-sm p-3'>({item.duration}min)</span> </div>
                <div>{item.price}</div>
                <button className=' bg-white font-extrabold' onClick={() => removeFromCart(item)}>
                  <span className='font-extrabold text-red-400 text-xl'>×</span>
                </button>
              </div>
              <hr style={{ width: "100%", margin: "5px 0" }} />
            </div>
          ))}

          <div className="cartItem">
            <div className="font-semibold">Total</div>
            <div className="font-semibold">{calculateTotal().toFixed(2)}</div>
          </div>
          <button className={`bookNowButton`} onClick={handleBookNow} style={{ backgroundColor: '#de6364', }} >
            Book Now</button>
        </>
      ) : (
        <p>Please select a service</p>
      )}
    </div>
  );
};

export default Cart;
