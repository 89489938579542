import React, { useState, useEffect } from "react";
import axios from "axios";
import {Service} from "../../../types/types";

interface ComponentProps {
  id: number;
  business_name: string;
}

interface Amenity {
  id: number;
  icon: string;
  amenitie_name: string;
}

const Amenities: React.FC<ComponentProps> = ({ id, business_name }) => {
  const [amenities, setAmenities] = useState<Amenity[]>([]);

  useEffect(() => {
    axios
      .get(`/api/business/amenities/${id}`)
      .then((response) => {
        const data = response.data;
        setAmenities(data.data.amenities);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [id]);

  return (
    <>
  <div className="amenities" style={{
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
  }}>
    {amenities && amenities.length > 0 ? (
      amenities.map((amenity) => (
        <div className={` p-2 pl-6 pr-6 rounded flex items-center space-x-2 mt-2 mb-2  ${amenity.id != 0 ? 'ml-2' : ''}`} key={amenity.id}>
          <img alt={`${amenity.amenitie_name} facility at ${business_name}`} title={`${amenity.amenitie_name} facility at ${business_name}`} src={amenity.icon} width="16" height="16" />
          <span className="text-sm font-light">{amenity.amenitie_name}</span>
        </div>
      ))
    ) : (
      <></>
    )}
  </div>
</>
  );
};

export default Amenities;
