import React, { useEffect } from "react";

const PageLogout: React.FC = () => {
  const removeUserIdCookie = async () => {
    document.cookie = "userId=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  };

  useEffect(() => {
    const logoutAndRedirect = async () => {
      await removeUserIdCookie();
      window.location.href = "/";
    };

    logoutAndRedirect();
  }, []);

  return (
    <div className="flex items-center justify-center h-screen">
      <p className="text-xl text-gray-700 dark:text-gray-300">
        Logging out...
      </p>
    </div>
  );
};

export default PageLogout;
