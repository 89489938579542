import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Input from 'shared/Input/Input';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import axios from "axios";

export default function ForgotPass() {
  const [field, setField] = useState<string | null>(null);
  const [fieldError, setFieldError] = useState<string | null>(null);
  const [OtpSend, setOtpSend] = useState(false);
  const [countdown, setCountdown] = useState<number | null>(null);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordError, setPasswordError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [otpError, setOtpError] = useState(false);
  const [userId, setUserId] = useState<string>("");
  const [otp, setOtp] = useState<string>("");
  const [success, setSuccess] = useState(false);

  const sendField = () => {
    setIsLoading(true);
    setFieldError(null);
    axios.post("/api/customer/send/otp", {
      field
    }).then((response) => {
      console.log(response.data)
      if (response.data.code == 190) {
        setFieldError("Please enter a valid email address or phone number");
      } else if (response.data.code == 191) {
        setFieldError("Entered phone or email does not exist. Please create an account.");
      } else if (response.data.code == 200) {
        setUserId(response.data.userId);
        setOtpSend(true);
        startCountdown();
      }
    }).catch((error) => {
      console.log("Error sending OTP: ", error);
      alert("Error sending OTP. Please check your internet connection");
    }).finally(() => {
      setIsLoading(false);
    });
  }

  const startCountdown = () => {
    setCountdown(80);
    const intervalId = setInterval(() => {
      setCountdown((prevCountdown) => {
        if (prevCountdown === null || prevCountdown === undefined) {
          clearInterval(intervalId);
          return null;
        }
        if (prevCountdown === 1) {
          clearInterval(intervalId);
          return null;
        }
        return prevCountdown - 1;
      });
    }, 1000);
  };

  useEffect(() => {
    if (countdown === null) {
      setOtpSend(false);
    }
  }, [countdown]);

  useEffect(() => {
    if (password && confirmPassword && password !== confirmPassword) {
      setPasswordError('Passwords do not match');
    } else {
      setPasswordError(null);
    }
  }, [password, confirmPassword]);

  const isButtonDisabled = password === '' || confirmPassword === '' || password !== confirmPassword || otp === '';


  const ChangePassword = (userId: string) => {
    setOtpError(false)
    axios.post("/api/customer/change/password", {
      userId,
      password,
      otp
    }).then((response) => {
      console.log(response.data);
      if(response.data.code == 230) {
        setOtpError(true);
      }
      if(response.data ==="password updated successfully") {
        setSuccess(true);
      }
    }).catch((error) => {
      console.log(error);
      alert("Error updating  password. Please check your internet connection"); 
    })
  }

  return (
    <>
      <div data-nc-id="PageForgot">
        <Helmet>
          <title>Feelaxo - Change your password</title>
        </Helmet>
        <div className="container mb-24 lg:mb-32">
          <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
            Change Password
          </h2>
          <div className="max-w-md mx-auto space-y-6">
            <div className="grid gap-3">
              <div className="grid grid-cols-1 gap-6">
                <label className="block">
                  <span className="text-neutral-800 dark:text-neutral-200">
                    Email or Phone
                  </span>
                  {fieldError != null && (
                    <p className="text-red-500">{fieldError}</p>
                  )}
                  <Input
                    type="text"
                    placeholder="Enter email or phone"
                    className="mt-1 mb-4"
                    onChange={(e) => { setField(e.target.value) }}
                    required
                  />
                    <ButtonPrimary onClick={() => { sendField() }} disabled={isLoading || OtpSend}>
                    {isLoading ? 'Loading...' : (OtpSend ? <>Resend OTP{countdown !== null && ` (${countdown})`}</> : <>Send OTP</>)}
                  </ButtonPrimary>
                </label>
                {otpError && (
                    <p className="text-red-500">Incorrect OTP</p>
                  )}
                <Input
                  type="text"
                  placeholder="Enter OTP"
                  maxLength={6}
                  onChange={(e) => {
                    setOtp(e.target.value)
                  }}
                  className={`mt-1 ${otpError && ("border-red-500")}`}
                  required
                />
                <label className="block">
                  <span className="text-neutral-800 dark:text-neutral-200">
                    Enter New Password
                  </span>
                  <Input
                    type="password"
                    className="mt-1"
                    placeholder="*******"
                    required
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </label>
                <label className="block">
                  <span className="text-neutral-800 dark:text-neutral-200">
                    Confirm New Password
                  </span>
                  <Input
                    type="password"
                    className="mt-1"
                    placeholder="*******"
                    required
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                  {passwordError && <p className="text-red-500">{passwordError}</p>}
                </label>
                <ButtonPrimary type="submit" disabled={isButtonDisabled} onClick={() => {ChangePassword(userId)}}>Change Password</ButtonPrimary>
              </div>
            </div>
          </div>
        </div>
      </div>
      {
        success && (
          <div>
          <div className="fixed top-0 left-0 w-full h-full bg-white" style={{ zIndex: 50 }} />
          <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50 w-80 shadow-xl text-center p-4 rounded-lg border">
            <h1 className="font-bold text-xl text-green-800">Password changed successfully</h1><br />

            <a href="/login">
              <button className="bg-black w-46 text-white px-4 py-2 rounded-md mt-4">
                Login
              </button>
            </a>
          </div>
        </div>
        )
      }
    </>
  );
}
