import React, { useState, useEffect } from "react";
import axios from "axios";
import Avatar from "shared/Avatar/Avatar";
import { StarIcon } from "@heroicons/react/24/solid";
import { Rating } from "../../../types/types"

interface RatingSectionProps {
    business_id: number;
}


const RatingSection: React.FC<RatingSectionProps> = ({
    business_id
}) => {

    const [ratings, setRatings] = useState<Rating[] | null>(null);

    useEffect(() => {
        axios.post('/api/business/ratings', {
            business_id
        }).then((response) => { 
            setRatings(response.data);
        }).catch((error) => {
            console.log(error);
        });
    }, [business_id]); // Specify dependency here

    function removeTimeFromDate(dateTimeString: string): string {
        const parts = dateTimeString.split(',');
        return parts[0].trim();
    }

    const renderStars = (rating: number): JSX.Element[] => {
        const stars = [];
        for (let i = 0; i < 5; i++) {
            stars.push(
                <StarIcon
                    key={i}
                    className={`w-4 h-4 ${
                        i < rating ? 'text-black' : 'text-gray-400'
                    }`}
                />
            );
        }
        return stars;
    };

    return (
        <>
            {ratings && (
                <>
                    {ratings.map((review, index) => (
                        <div
                            key={index}
                            className={`nc-CommentListing flex space-x-4`}
                            data-nc-id="CommentListing"
                        >
                            <div className="pt-0.5">
                                <Avatar
                                    sizeClass="h-10 w-10 text-lg"
                                    radius="rounded-full"
                                    userName={review.user_name}
                                    imgUrl={""}
                                />
                            </div>
                            <div className="flex-grow">
                                <div className="flex justify-between space-x-3">
                                    <div className="flex flex-col">
                                        <div className="text-sm font-semibold">
                                            <span>{review.user_name}</span>
                                        </div>
                                        <span className="text-sm text-neutral-500 dark:text-neutral-400 mt-0.5">
                                            {removeTimeFromDate(review.added_on)}
                                        </span>
                                    </div>
                                    <div className="flex text-black">
                                        {renderStars(review.rating)}
                                    </div>
                                </div>
                                <span className="block mt-3 text-neutral-6000 dark:text-neutral-300">
                                    {review.description}
                                </span>
                            </div>
                        </div>
                    ))}
                </>
            )}
        </>
    );
}

export default RatingSection;
