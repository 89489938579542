import React, { useState } from 'react';
import './style.css';
import Default from 'images/placeholder-small.png';
import { faImage } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Badge from "shared/Badge/Badge";
import ListingImageGallery from 'components/ListingImageGallery/ListingImageGallery';

interface DesktopImageGalleryProps {
  imageUrls: string[];
  thumbnail: string;
  business_type: number;
  business_name: string;
  city: string;
}

const spaKeywords = [
  "spa near by me", "spa close to me", "near spa me",
  "near by me spa", "massages near me", "spas and massages near me",
  "body massages near me", "best spa near me", "spa best near me",
  "body spa near me", "spa massages near me", "spa services near me",
  "men spa near me", "male spa near me", "full body spa near me",
  "spa around me", "good massages near me", "mens massage",
  "nearest spa near me", "for men spa", "day spa near me",
  "massage mens near me", "near me best spa"
];
const salonKeywords = [
  "best salon near me", "salon services near me",
  "salon programs", "salon software", "beauty salon near me",
  "hair salon near me", "beauty salon software", "top salon near me",
  "hair stylist app", "salon around me", "nearest salon near me",
  "online hair stylist", "haircut online"
];
const wellnessKeywords = [
  "best wellness center near me",
  "wellness services near me", "wellness spa near me",
  "wellness programs near me", "wellness software"
];

const getKeywordsByType = (business_type: number) => {
  switch (business_type) {
    case 1: return spaKeywords;
    case 2: return salonKeywords;
    case 3: return wellnessKeywords;
    default: return [];
  }
};

const getImageAltAndTitle = (index: number, business_type: number, business_name: string, city: string) => {
  const keywords = getKeywordsByType(business_type);
  const keyword = keywords[index % keywords.length];
  return {
    alt: `${keyword} at ${business_name} in ${city}`,
    title: `${keyword} at ${business_name} in ${city}`,
  };
};

const DesktopImageGallery: React.FC<DesktopImageGalleryProps> = ({ imageUrls, business_type, thumbnail, business_name, city }) => {
  const [maximizedImage, setMaximizedImage] = useState<number | null>(null);

  const maximizeImage = (index: number) => {
    setMaximizedImage(index);
  };

  const closeMaximizedImage = () => {
    setMaximizedImage(null);
  };

  return (
    <>
      <header className="rounded-md sm:rounded-xl">
        <div className="relative h-[24vw] md:mb-24 lg:mb-36 grid grid-cols-2 sm:grid-cols-2 gap-1 sm:gap-2">
          <div className="absolute">
            <div className="absolute flex w-full p-4 pt-6 z-10 h-10 items-center justify-between">
              <div>
                {business_type === 1 && <Badge name="Spa" />}
                {business_type === 2 && <Badge name="Salon" />}
                {business_type === 3 && <Badge name="Wellness" />}
              </div>
            </div>
          </div>
          <div className="w-full h-full rounded-lg overflow-hidden cursor-pointer drop-shadow-xl hover:drop-shadow-lg" onClick={() => maximizeImage(0)}>
            {thumbnail ? (
              <img
                alt={`best ${business_type === 1 ? "Spa" : ""}${business_type === 2 ? "Salon" : ""}${business_type === 3 ? "Wellness": ""} ${business_name} in ${city}`}
                title={`best ${business_type === 1 ? "Spa" : ""}${business_type === 2 ? "Salon" : ""}${business_type === 3 ? "Wellness": ""} ${business_name} in ${city}`}
                src={thumbnail}
                className="w-full h-full rounded-md object-cover"
              />
            ) : (
              <img
                alt={getImageAltAndTitle(0, business_type, business_name, city).alt}
                title={getImageAltAndTitle(0, business_type, business_name, city).title}
                src={imageUrls[0]}
                className="absolute inset-0 object-cover rounded-md sm:rounded-xl w-full h-full"
              />
            )}
            <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer" />

            <div className="absolute bg-white drop-shadow-xl p-4 rounded-md cursor-pointer bottom-2 left-2" onClick={() => maximizeImage(0)}>
              <FontAwesomeIcon className="mr-3" icon={faImage} />
              See all images
            </div>
          </div>
          <div className="grid grid-cols-2 gap-2">
            {[...Array(4)].map((_, index) => (
              <div key={index} className="aspect-w-4 aspect-h-3 sm:aspect-w-4 sm:aspect-h-3" onClick={() => maximizeImage(index)}>
                {imageUrls[index] ? (
                  <img
                    alt={getImageAltAndTitle(index, business_type, business_name, city).alt}
                    title={getImageAltAndTitle(index, business_type, business_name, city).title}
                    src={imageUrls[index]}
                    className="absolute inset-0 object-cover rounded-md sm:rounded-xl w-full h-full"
                  />
                ) : (
                  <img
                    alt={getImageAltAndTitle(index, business_type, business_name, city).alt}
                    title={getImageAltAndTitle(index, business_type, business_name, city).title}
                    src={Default}
                    className="absolute inset-0 object-cover rounded-md sm:rounded-xl w-full h-full"
                  />
                )}
                <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 rounded-xl transition-opacity cursor-pointer" />
              </div>
            ))}
          </div>
        </div>
      </header>

      {maximizedImage !== null && (
        <ImageGalleryPopup
          imageUrls={imageUrls}
          currentIndex={maximizedImage}
          onClose={closeMaximizedImage}
        />
      )}
    </>
  );
};

interface ImageGalleryPopupProps {
  imageUrls: string[];
  currentIndex: number;
  onClose: () => void;
}

const ImageGalleryPopup: React.FC<ImageGalleryPopupProps> = ({ imageUrls, currentIndex, onClose }) => {
  const [fullImageUrl, setFullImageUrl] = useState<string | null>(null);

  const showFullImage = (index: number) => {
    setFullImageUrl(imageUrls[index]);
  };

  const closeFullImage = () => {
    setFullImageUrl(null);
  };

  return (
    <>
      <ListingImageGallery photos={imageUrls} isShowModal={true} onClose={onClose} />
    </>
  );
};

export default DesktopImageGallery;
