import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { CheckIcon } from "@heroicons/react/24/outline";
import {Staff} from "../../../types/types";

interface DateAndTimeProp {
  businessId?: number;
  selectedStaff: Staff | null;
  selectDate: (date: Date) => void;
  setSelectedTime: (time: string) => void;
  selectedTime: string;
  bookingDate: Date | null;
}

interface TimeSlot {
  time: string;
  staff: number;
}

const DateandTimeSelector: React.FC<DateAndTimeProp> = ({
  businessId,
  selectedStaff,
  selectDate,
  setSelectedTime,
  selectedTime,
  bookingDate,
}) => {
  // Helper to normalize date to the start of the day
  const startOfDay = (date: Date): Date => {
    const newDate = new Date(date);
    newDate.setHours(0, 0, 0, 0);
    return newDate;
  };

  const today = startOfDay(new Date());
  const [selectedDate, setSelectedDate] = useState<Date | null>(today);
  const [currentDate, setCurrentDate] = useState<Date>(today);
  const [displayedDates, setDisplayedDates] = useState<Date[]>([]);
  const [selectedDay, setSelectedDay] = useState<string | null>(null);
  const [timeSlots, setTimeSlots] = useState<TimeSlot[] | null>(null);
  const [selectedTimePeriod, setSelectedTimePeriod] = useState<string>("morning");
  const containerRef = useRef<HTMLDivElement | null>(null);

  const [availability, setAvailability] = useState({
    morning: false,
    afternoon: false,
    evening: false,
  });

  useEffect(() => {
    generateDates();
  }, [currentDate]);

  useEffect(() => {
    handleDateClick(today);
  }, []);
  
  const generateDates = () => {
    const datesArray: Date[] = [];
    const startDate = startOfDay(currentDate);

    let numberOfDatesToShow = 9;
    if (window.innerWidth < 1200) {
      numberOfDatesToShow = 7;
    }
    if (window.innerWidth < 800) {
      numberOfDatesToShow = 8;
    }
    if(window.innerWidth < 600) {
      numberOfDatesToShow = 4;
    }

    for (let i = 0; i < numberOfDatesToShow; i++) {
      const date = new Date(startDate);
      date.setDate(startDate.getDate() + i);
      datesArray.push(startOfDay(date));
    }

    setDisplayedDates(datesArray);
  };

  // Scroll into view when dates change
  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollIntoView({ behavior: "smooth", block: "nearest" });
    }
  }, [displayedDates]);

  // Handle previous button click
  const handlePrevClick = () => {
    const today = startOfDay(new Date());
    const newDate = new Date(currentDate);
    newDate.setDate(currentDate.getDate() - 4);
    if (startOfDay(newDate) >= today) {
      setCurrentDate(startOfDay(newDate));
    }
  };

  // Handle next button click
  const handleNextClick = () => {
    const newDate = new Date(currentDate);
    newDate.setDate(currentDate.getDate() + 4);
    setCurrentDate(startOfDay(newDate));
  };

  const handleDateClick = async (date: Date) => {
    const normalizedDate = startOfDay(date);
    const utcDate = new Date(Date.UTC(
      normalizedDate.getFullYear(),
      normalizedDate.getMonth(),
      normalizedDate.getDate()
    ));
    const formattedDay = utcDate.toLocaleDateString("en", { weekday: "long" }).toLowerCase();

    await setSelectedDate(normalizedDate);
    await setSelectedDay(formattedDay);
    selectDate(normalizedDate);
    setSelectedTime('');

    axios.post('/api/business/timeslots', {
      date: utcDate.toISOString(),
      businessId: businessId,
      staffId: selectedStaff?.id,
      day: formattedDay
    }).then((response) => {
      const fetchedTimeSlots = response.data;
      setTimeSlots(fetchedTimeSlots);

      const morningSlots = filterTimeSlots(fetchedTimeSlots, 'morning');
      const afternoonSlots = filterTimeSlots(fetchedTimeSlots, 'afternoon');
      const eveningSlots = filterTimeSlots(fetchedTimeSlots, 'evening');

      const isMorningAvailable = morningSlots.length > 0;
      const isAfternoonAvailable = afternoonSlots.length > 0;
      const isEveningAvailable = eveningSlots.length > 0;

      setAvailability({
        morning: isMorningAvailable,
        afternoon: isAfternoonAvailable,
        evening: isEveningAvailable,
      });

      if (selectedTimePeriod === 'morning' && !isMorningAvailable) {
        if (isAfternoonAvailable) {
          setSelectedTimePeriod('afternoon');
        } else if (isEveningAvailable) {
          setSelectedTimePeriod('evening');
        }
      } else if (selectedTimePeriod === 'afternoon' && !isAfternoonAvailable) {
        if (isMorningAvailable) {
          setSelectedTimePeriod('morning');
        } else if (isEveningAvailable) {
          setSelectedTimePeriod('evening');
        }
      } else if (selectedTimePeriod === 'evening' && !isEveningAvailable) {
        if (isMorningAvailable) {
          setSelectedTimePeriod('morning');
        } else if (isAfternoonAvailable) {
          setSelectedTimePeriod('afternoon');
        }
      }
    }).catch((error) => {
      console.log(error);
    });
  };

  function convertTimeToAMPM(timeString: string): string {
    const [hours, minutes] = timeString.split(':').map(Number);
    const period = hours >= 12 ? 'PM' : 'AM';
    const hours12 = hours % 12 || 12;
    const timeAMPM = `${hours12}:${minutes.toString().padStart(2, '0')} ${period}`;

    return timeAMPM;
  }

  const filterTimeSlots = (timeSlots: TimeSlot[], period: string): TimeSlot[] => {
    if (!timeSlots) return [];

    let startHour: number, endHour: number;

    switch (period) {
      case 'morning':
        startHour = 6;
        endHour = 12;
        break;
      case 'afternoon':
        startHour = 12;
        endHour = 18;
        break;
      case 'evening':
        startHour = 18;
        endHour = 24;
        break;
      default:
        return timeSlots;
    }

    return timeSlots.filter(slot => {
      const slotHour = parseInt(slot.time.split(':')[0], 10);
      return slotHour >= startHour && slotHour < endHour;
    });
  };

  return (
    <div className="date-time-selector">
      <h1 className="text-lg md:text-xl p-2 md:p-8">Select Time</h1>

      <div className="px-2 md:px-8" ref={containerRef}>
        <div className="parent-container flex justify-between">
          <div className="grid" style={{ gridTemplateColumns: "1fr 1fr", alignItems: "baseline" }}>
            {displayedDates.length > 0 && (
              <>
                <div className="month font-bold text-xl">
                  {displayedDates[0].toLocaleDateString("en", { month: "long" })}
                </div>
                <div className="font-bold text-xl">&nbsp;{displayedDates[0].getFullYear()}</div>
              </>
            )}
          </div>
          <div className="flex justify-between">
            <div
              onClick={handlePrevClick}
              className="chevron-left rounded-lg text-gray-500 p-2 cursor-pointer hover:bg-gray-200 items-center text-center"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-chevron-left" viewBox="0 0 16 16">
                <path fillRule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
              </svg>
            </div>

            <div
              onClick={handleNextClick}
              className="rounded-lg text-gray-500 p-2 cursor-pointer hover:bg-gray-200 items-center text-center"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-chevron-right" viewBox="0 0 16 16">
                <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708" />
              </svg>
            </div>
          </div>
        </div>

        {/* date */}
        <div className="flex mt-4 mx-auto cursor-pointer w-full">
          {displayedDates.map((date, index) => (
            <div
              key={index}
              className={`p-2 mx-1 `}
              onClick={() => handleDateClick(date)}
            >
              <div className={`justify-center rounded-full font-bold text-lg border px-4 py-2 items-center flex w-16 h-16 md:h-16 md:w-16 ${selectedDate?.toLocaleDateString() === date.toLocaleDateString() && "bg-black text-white"} ${bookingDate === startOfDay(date) && "bg-black text-white"}
                ${
                  date && (date.getDate() === today.getDate() &&
                          date.getMonth() === today.getMonth() &&
                          date.getFullYear() === today.getFullYear())
                    && ("bg-red-400 text-white")
                }
                `}>

                {
                  date && (date.getDate() === today.getDate() &&
                          date.getMonth() === today.getMonth() &&
                          date.getFullYear() === today.getFullYear())
                    ? (<div className="text-sm">Today</div>) : <div className="text-xl">{date.getDate()}</div>
                }
              </div>
              <div className="text-center mt-2 font-light text-sm">
                {date.toLocaleDateString("en", { weekday: "short" })}
              </div>
            </div>
          ))}
        </div>

        {/* time period buttons */}
        <div className="flex justify-between px-4  py-2 border border-gray-400 bg-gray-100 rounded-t-xl w-full items-center mx-auto mt-4 space-x-2">
          {["morning", "afternoon", "evening"].map(period => (
            <button
              key={period}
              onClick={() => {
                if (availability[period as keyof typeof availability]) {
                  setSelectedTimePeriod(period);
                }
              }}
              className={`rounded-xl py-2 px-4 font-semibold ${selectedTimePeriod === period ? "bg-black text-white" : "bg-white border-gray-400 border text-gray-800"} ${!availability[period as keyof typeof availability] && "cursor-not-allowed opacity-50"}`}
              disabled={!availability[period as keyof typeof availability]}
            >
              {period.charAt(0).toUpperCase() + period.slice(1)}
            </button>
          ))}
        </div>

        {/* time slots */}
        <div className="scrollbar w-full mx-auto flex max-h-[50vh] lg:max-h-80 lg:h-96 overflow-x-auto">
          {
            timeSlots == null ? (
              <div>
                <div className="text-red-500 flex w-full justify-center items-center text-center mt-6 font-bold">
                  <div>Please select a date</div>
                </div>
              </div>
            
            ) : (
              <div>
                {
                  timeSlots.length !== 0 ? (
                    <div className="flex flex-wrap pb-48 mb-96 pt-8">
                      {filterTimeSlots(timeSlots, selectedTimePeriod).map((slot, index) => (
                        <div key={index} className="w-1/3 w-1/3 lg:w-1/4 p-2 lg:p-4">
                          <div
                           onClick={() => {
                            if (slot.staff !== 0) {
                              setSelectedTime(slot.time);
                            }
                          }} className={`${slot.staff == 0 ? "disable border border-red-500 opacity-50 cursor-not-allowed" : "border cursor-pointer"} ${selectedTime == slot.time ? "border border-black hover:border-black bg-gray-100" : " border-gray-400"} hover:border-black flex items-center justify-center hover:bg-gray-100 rounded-lg p-2 text-center`}>
                            {selectedTime == slot.time && (<CheckIcon className="text-sm w-6 text-green-600 font-bold mr-2" />)}
                            <span className="text-lg ">{convertTimeToAMPM(slot.time)}</span>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className="text-red-500 flex justify-center mx-auto text-center mt-6 font-bold">
                    The venue will remain closed on this date. Please select another date.
                  </div>
                  )
                }
              </div>
            )
          }
        </div>
      </div>
    </div>
  );
};

export default DateandTimeSelector;
