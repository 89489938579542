import React, { useState } from 'react';
import Slider from 'react-slick';
import { useNavigate } from 'react-router-dom';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Badge from "shared/Badge/Badge";
import ListingImageGallery from 'components/ListingImageGallery/ListingImageGallery';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShare } from "@fortawesome/free-solid-svg-icons";
import BtnLikeIcon from "components/BtnLikeIcon/BtnLikeIcon";
import { FaArrowLeft } from "react-icons/fa";

interface MobileImageGalleryProps {
  imageUrls: string[];
  business_type: number;
  business_id: number;
  business_name: string;
  city: string;
}

const spaKeywords = [
  "best spa deals near me", "spa near me", "spa near by me", "spa close to me", "near spa me",
  "near by me spa", "massages near me", "spas and massages near me",
  "body massages near me", "best spa near me", "spa best near me",
  "body spa near me", "spa massages near me", "spa services near me",
  "men spa near me", "male spa near me", "full body spa near me",
  "spa around me", "good massages near me", "mens massage",
  "nearest spa near me", "for men spa", "day spa near me",
  "massage mens near me", "near me best spa"
];
const salonKeywords = [
  "best salon deals near me", "salon near me", "best salon near me", "salon services near me",
  "salon programs", "salon software", "beauty salon near me",
  "hair salon near me", "beauty salon software", "top salon near me",
  "hair stylist app", "salon around me", "nearest salon near me",
  "online hair stylist", "haircut online"
];
const wellnessKeywords = [
  "wellness center near me", "best wellness center near me",
  "wellness services near me", "wellness spa near me",
  "wellness programs near me", "wellness software"
];

const getKeywordsByType = (business_type: number) => {
  switch (business_type) {
    case 1: return spaKeywords;
    case 2: return salonKeywords;
    case 3: return wellnessKeywords;
    default: return [];
  }
};

const getImageAltAndTitle = (index: number, business_type: number, business_name: string, city: string) => {
  const keywords = getKeywordsByType(business_type);
  const keyword = keywords[index % keywords.length];
  return {
    alt: `${keyword} at ${business_name} in ${city}`,
    title: `${keyword} at ${business_name} in ${city}`,
  };
};

const MobileImageGallery: React.FC<MobileImageGalleryProps> = ({ imageUrls, business_type, business_id, business_name, city }) => {
  const [maximizedImage, setMaximizedImage] = useState<number | null>(null);
  const navigate = useNavigate();

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    showThumbs: false
  };

  const maximizeImage = (index: number) => {
    setMaximizedImage(index);
  };

  const closeMaximizedImage = () => {
    setMaximizedImage(null);
  };

  return (
    <div className="mobile-image-gallery w-full h-60 sm:h-96 absolute left-0 top-0 right-0 overflow-hidden cursor-pointer">
      <div className="absolute bottom-0 left-0 z-40 p-2">
          {business_type === 1 && <Badge name="spa" />}
          {business_type === 2 && <Badge name="salon" />}
          {business_type === 3 && <Badge name="Wellness" />}
      </div>
      <div className="absolute top-0 left-0 w-full z-40 p-2 flex items-center justify-between">
        <div className="flex items-center justify-between w-full space-x-4 space-y-2">
          <div>
            <button 
              className="p-2 rounded-full bg-black bg-opacity-30 hover:bg-opacity-50 text-white cursor-pointer hover:bg-white hover:text-black"
              onClick={() => navigate(-1)}
            >
              <FaArrowLeft />
            </button>
          </div>

          <div className="flex">
            <button 
              onClick={() => { 
                if (navigator.share) { 
                  navigator.share({ 
                    title: 'Share Venue', 
                    text: 'Feelaxo - Book salons, spas and wellness centers online', 
                    url: window.location.href, 
                  })
                  .then(() => console.log('Share successful'))
                  .catch((error) => console.error('Error sharing:', error)); 
                } 
              }} 
              className="p-1 pl-2 pr-2 rounded-full bg-black bg-opacity-30 hover:bg-opacity-50 text-white mr-4 cursor-pointer"
            >
              <FontAwesomeIcon icon={faShare} />
            </button>

            <BtnLikeIcon business_id={business_id} />
          </div>
        </div>
      </div>

      <Slider {...settings}>
        {imageUrls.map((url, index) => (
          <div key={index} className="w-full h-60 sm:h-96 flex relative justify-center items-center">
            <img
              className="w-full h-60 sm:h-96"
              src={url}
              alt={getImageAltAndTitle(index, business_type, business_name, city).alt}
              title={getImageAltAndTitle(index, business_type, business_name, city).title}
              onClick={() => maximizeImage(index)}
            />
          </div>
        ))}
      </Slider>

      {maximizedImage !== null && (
        <ListingImageGallery photos={imageUrls} onClose={closeMaximizedImage} isShowModal={true} />
      )}
    </div>
  );
};

export default MobileImageGallery;
