import React, { FC, useState } from "react";
import { Helmet } from "react-helmet";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import axios from "axios";
import { Alert } from "shared/Alert/Alert";

export interface PageContactProps {
  className?: string;
}

const DemoForm: FC<PageContactProps> = ({ className = "" }) => {
  const [formData, setFormData] = useState({
    fullName: "",
    contactNumber: "",
    email: "",
    location: "",
    businessType: "",
    staffCount: "",
    outletCount: "",
    usage: ""
  });

  const [errors, setErrors] = useState({
    fullName: "",
    contactNumber: "",
    email: "",
    location: "",
    businessType: "",
    staffCount: "",
    outletCount: "",
    usage: ""
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [alert, setAlert] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" })); // Clear error message on change
  };

  const validateForm = () => {
    let valid = true;
    let newErrors = { ...errors };
    const { fullName, contactNumber, email, location, businessType, staffCount, outletCount, usage } = formData;

    if (!fullName) {
      newErrors.fullName = "Full Name is required.";
      valid = false;
    }
    if (!contactNumber) {
      newErrors.contactNumber = "Contact Number is required.";
      valid = false;
    } else if (!/^\d+$/.test(contactNumber)) {
      newErrors.contactNumber = "Contact Number must be numeric.";
      valid = false;
    }
    if (!email) {
      newErrors.email = "Email is required.";
      valid = false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      newErrors.email = "Email address is invalid.";
      valid = false;
    }
    if (!location) {
      newErrors.location = "Location is required.";
      valid = false;
    }
    if (!businessType) {
      newErrors.businessType = "Business Type is required.";
      valid = false;
    }
    if (!staffCount) {
      newErrors.staffCount = "Staff Count is required.";
      valid = false;
    } else if (!/^\d+$/.test(staffCount)) {
      newErrors.staffCount = "Staff Count must be numeric.";
      valid = false;
    }
    if (!outletCount) {
      newErrors.outletCount = "Outlet Count is required.";
      valid = false;
    } else if (!/^\d+$/.test(outletCount)) {
      newErrors.outletCount = "Outlet Count must be numeric.";
      valid = false;
    }
    if (!usage) {
      newErrors.usage = "Usage is required.";
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);

    if (!validateForm()) {
      setIsSubmitting(false);
      return;
    }

    try {
      const response = await axios.post("/api/customer/demo", formData);
      console.log("Form submitted successfully", response.data);
      setAlert(true);
      setFormData({
        fullName: "",
        contactNumber: "",
        email: "",
        location: "",
        businessType: "",
        staffCount: "",
        outletCount: "",
        usage: ""
      });
    } catch (error) {
      console.error("Error submitting form", error);
      setErrors((prevErrors) => ({
        ...prevErrors,
        submit: "Failed to send message. Please try again later."
      }));
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>Request a Demo | Feelaxo | Leading Booking & CRM Software for Salons, Spas & Wellness Businesses</title>
        <meta name="description" content="Request a free demo for Feelaxo today to streamline your operations and enhance customer satisfaction." />
        <meta name="keywords" content="Feelaxo demo, Salon booking software, Spa CRM software, Wellness business software, Free salon software, Spa management software, Wellness CRM software, Online booking platform, Salon software, Spa software" />
      </Helmet>
      {alert && (
        <div className="z-50 w-full fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative" role="alert">
          <span className="block sm:inline">Demo Requested Successfully</span>
        </div>
      )}
      <div
        className={`nc-PageContact overflow-hidden ${className}`}
        data-nc-id="PageContact"
      >
        <div className="mb-24 lg:mb-32">
          <h2 className="my-16 sm:my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
            Request a free demo
          </h2>
          <div className="container mx-auto">
            <div className="flex-shrink-0 grid w-80 lg:w-96 mx-auto">
              <div>
                <form className="grid grid-cols-1 gap-6" onSubmit={handleSubmit}>
                  <label className="block">
                    <Label>Full Name</Label>
                    <Input
                      name="fullName"
                      placeholder="your Name"
                      type="text"
                      className="mt-1"
                      value={formData.fullName}
                      onChange={handleChange}
                    />
                    {errors.fullName && <p className="text-red-600">{errors.fullName}</p>}
                  </label>
                  <label className="block">
                    <Label>Contact Number</Label>
                    <Input
                      name="contactNumber"
                      placeholder="your phone number"
                      type="text"
                      className="mt-1"
                      minLength={10}
                      value={formData.contactNumber}
                      onChange={handleChange}
                    />
                    {errors.contactNumber && <p className="text-red-600">{errors.contactNumber}</p>}
                  </label>
                  <label className="block">
                    <Label>Email address</Label>
                    <Input
                      name="email"
                      type="email"
                      placeholder="your email address"
                      className="mt-1"
                      value={formData.email}
                      onChange={handleChange}
                    />
                    {errors.email && <p className="text-red-600">{errors.email}</p>}
                  </label>
                  <label className="block">
                    <Label>Enter Location</Label>
                    <Input
                      name="location"
                      placeholder="your location"
                      type="text"
                      className="mt-1"
                      value={formData.location}
                      onChange={handleChange}
                    />
                    {errors.location && <p className="text-red-600">{errors.location}</p>}
                  </label>
                  <label className="block">
                    <Label>Business Type</Label>
                    <select
                      name="businessType"
                      className="mt-1"
                      value={formData.businessType}
                      onChange={handleChange}
                    >
                      <option value="">Select business type</option>
                      <option value="Salon">Salon</option>
                      <option value="Spa">Spa</option>
                      <option value="Wellness">Wellness</option>
                      <option value="Salon & Spa">Salon & Spa</option>
                    </select>
                    {errors.businessType && <p className="text-red-600">{errors.businessType}</p>}
                  </label>
                  <label className="block">
                    <Label>Staff Count</Label>
                    <Input
                      name="staffCount"
                      placeholder="number of staff"
                      type="text"
                      className="mt-1"
                      value={formData.staffCount}
                      onChange={handleChange}
                    />
                    {errors.staffCount && <p className="text-red-600">{errors.staffCount}</p>}
                  </label>
                  <label className="block">
                    <Label>Number Of Outlets</Label>
                    <Input
                      name="outletCount"
                      placeholder="number of outlets"
                      type="text"
                      className="mt-1"
                      value={formData.outletCount}
                      onChange={handleChange}
                    />
                    {errors.outletCount && <p className="text-red-600">{errors.outletCount}</p>}
                  </label>
                  <label className="block">
                    <Label>When do you plan to use the software</Label>
                    <select
                      name="usage"
                      className="mt-1"
                      value={formData.usage}
                      onChange={handleChange}
                    >
                      <option value="">Select one</option>
                      <option value="Immediately">Immediately</option>
                      <option value="1-2 months">1-2 months</option>
                      <option value="Just Exploring">Just Exploring</option>
                    </select>
                    {errors.usage && <p className="text-red-600">{errors.usage}</p>}
                  </label>
                  <div>
                    <ButtonPrimary type="submit" disabled={isSubmitting}>
                      {isSubmitting ? "Sending..." : "Request Demo"}
                    </ButtonPrimary>
                  </div>
                </form>
              
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DemoForm;
