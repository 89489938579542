import React, { useEffect, useState } from "react";

const SuccessPage = () => {
  const [countdown, setCountdown] = useState(3);

  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown((prevCountdown) => {
        if (prevCountdown <= 1) {
          clearInterval(timer);
          window.location.href = "/orders";
          return 0;
        }
        return prevCountdown - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  return (
    <div>
      <div className="fixed top-0 left-0 w-full h-full bg-white" style={{ zIndex: 50 }} />
      <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50 w-80 shadow-xl text-center p-4 rounded-lg border">
        <h1 className="font-bold text-xl text-green-800">Order successfully placed!</h1>
        <p className="mt-4">Redirecting to orders in {countdown} seconds...</p>
        <a href="/orders">
          <button className="bg-black w-46 text-white px-4 py-2 rounded-md mt-4">
            View Order
          </button>
        </a>
      </div>
    </div>
  );
};

export default SuccessPage;