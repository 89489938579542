import React, { FC } from "react";
import imagePng from "images/feelaxo home page banner .jpg";
import orderimg from "images/feelaxo-order.jpg";
import deskimg from "images/feelaxo-desk.jpg";

export interface ForBusinessProps {
    className?: string;
}

const ForBusiness: FC<ForBusinessProps> = ({ className = "" }) => {
    return (
        <>        <div
            className={`nc-SectionHero flex flex-col-reverse lg:flex-col relative ${className}`}
            data-nc-id="SectionHero"
        >
            <div className="flex flex-col lg:flex-row lg:items-center p-10 m-20">
                <div className="flex-shrink-0 lg:w-1/2 flex flex-col items-start space-y-8 sm:space-y-10 pb-14 lg:pb-64 xl:pr-14 lg:mr-10 xl:mr-0">
                    <h2 className="font-medium text-3xl md:text-3xl text-red-500 xl:text-5xl !leading-[114%] ">
                        DISCOVER THE ULTIMATE SOLUTION FOR SALONS & SPAS
                    </h2>
                    <span className="text-base md:text-lg text-neutral-500 dark:text-neutral-400">
                        Automate Your Salon or Spa Business
                    </span>
                    <button className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded inline-flex items-center">
                        Try Now
                        <svg className="w-4 h-4 ml-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path>
                        </svg>
                    </button>
                </div>
                <div className="flex-grow">
                    <img className="w-50 " src={imagePng} alt="hero" />
                </div>
            </div>
        </div>
            <div className="p-4 lg:p-8 m-2 lg:m-8">
                <h2 className="text-2xl lg:text-5xl font-medium text-center">
                    All the things you require to run your business
                </h2><br />
                <span className="font-sans font-normal text-base lg:text-xl leading-relaxed text-neutral-700">
                    Manage and automate daily operations, including appointment scheduling, client administration, retail sales, employee administration, and payment processing.
                </span><br /><br />
                <div className="flex-grow rounded-lg border border-black">
                    <img className=" p-8 w-50 h-50  " src={orderimg} alt="hero " />
                </div>
            </div>
            <div className="flex flex-col lg:flex-row lg:items-center p-4 lg:p-8 m-2 lg:m-8 space-y-8 lg:space-y-0 lg:space-x-8">
                <div className="lg:w-1/3">
                    <h2 className="text-xl lg:text-2xl font-medium mb-2 text-red-500">Time Saving</h2>
                    <p className="text-sm lg:text-base text-neutral-700">
                        Increase your team’s productivity by switching<br /> out time-consuming chores for quick    procedures that require fewer clicks.
                    </p>
                </div>
                <div className="lg:w-1/3">
                    <h2 className="text-xl lg:text-2xl font-medium mb-2 text-red-500">Reduce Cost</h2>
                    <p className="text-sm lg:text-base text-neutral-700">
                        With tools and automations that make the<br /> front desk optional, rethink how you conduct <br />business.
                    </p>
                </div>
                <div className="lg:w-1/3">
                    <h2 className="text-xl lg:text-2xl font-medium mb-2 text-red-500">Efficiency</h2>
                    <p className="text-sm lg:text-base text-neutral-700">
                        Increase your team’s productivity by switching <br /> out time-consuming chores for quick <br />procedures that require fewer clicks.
                    </p>
                </div>
            </div>
            <div className="flex flex-col lg:flex-row lg:items-center p-4 lg:p-8 m-2 lg:m-8 space-y-8 lg:space-y-0 lg:space-x-8">
                <div className="lg:w-1/2">
                    <h2 className="text-xl lg:text-2xl font-medium mb-2 text-red-500">
                        Smart Automations for Efficiency and Excellence
                    </h2>
                    <p className="text-2xl font-bold">
                        Transforming experiences for clients and staff. </p>
                    <span> Elevate your business to new heights with intelligent automations that optimize every step of
                        the client journey. By reducing overhead costs, eliminating errors, and increasing efficiency, you’ll
                        create a superior experience for both your clients and staff.</span>
                </div>
                <div className="lg:w-1/3">

                    <div className="flex-grow rounded-lg border border-black">
                        <img className=" p-8 w-50 h-50  " src={orderimg} alt="hero " />
                    </div>
                </div>
            </div>


            <div className="flex flex-col lg:flex-row lg:items-center p-4 lg:p-8 m-2 lg:m-8 space-y-8 lg:space-y-0 lg:space-x-8">
                <div className="lg:w-1/3">

                    <div className="flex-grow rounded-lg border border-none">
                        <img className=" p-8 w-50 h-50  " src={deskimg} alt="hero " />
                    </div>
                </div>
                <div className="lg:w-1/2">
                    <h2 className="text-xl lg:text-2xl font-medium mb-2 text-red-500">
                        Smart Automations for Efficiency and Excellence
                    </h2>
                    <p className="text-2xl font-bold">
                        Transforming experiences for clients and staff. </p>
                    <span> Elevate your business to new heights with intelligent automations that optimize every step of
                        the client journey. By reducing overhead costs, eliminating errors, and increasing efficiency, you’ll
                        create a superior experience for both your clients and staff.</span><br /><br />
                    <div className="flex items-center space-x-2">
                        <button className="px-4 py-2 border border-orange-500 text-orange-500 bg-white rounded-full flex items-center space-x-1 hover:bg-orange-500 hover:text-white">
                            See Features
                            <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path>
                            </svg>
                        </button>
                    </div>

                </div>
            </div>
            <div className="flex flex-col lg:flex-row lg:items-center p-4 lg:p-8 m-2 lg:m-8 space-y-8 lg:space-y-0 lg:space-x-8">
             
                <div className="lg:w-1/3">  
                {/* <h1 className="text-center text-3xl lg:text-4xl font-bold">Testimonial</h1><br/> */}

                    <div className="flex items-center space-x-2">
                        <span className="text-orange-600 text-2xl">&#9733;</span>
                        <span className="text-orange-500 text-2xl">&#9733;</span>
                        <span className="text-orange-500 text-2xl">&#9733;</span>
                        <span className="text-orange-500 text-2xl">&#9733;</span>
                        <span className="text-orange-500 text-2xl">&#9733;</span>
                    </div>
                    <p className="text-sm lg:text-base text-neutral-700 mt-2">
                        The overall use of flatsome is very VERY useful. It<br /> lacks very few, if any,
                        things! I loved it and have<br /> created my first ever website Punsteronline.com!<br /> Best yet,
                        flatsome gets free updates that are great!<br /> (and the support is amazing as well!:)
                        <br />
                        <span className="font-semibold mt-2">Mark Jance / Facebook</span>
                    </p>
                </div>

                <div className="lg:w-1/3">
                    <div className="flex items-center space-x-2">
                        <span className="text-orange-500 text-2xl">&#9733;</span>
                        <span className="text-orange-500 text-2xl">&#9733;</span>
                        <span className="text-orange-500 text-2xl">&#9733;</span>
                        <span className="text-orange-500 text-2xl">&#9733;</span>
                        <span className="text-orange-500 text-2xl">&#9733;</span>
                    </div>
                    <p className="text-sm lg:text-base text-neutral-700 mt-2">
                        This is a FANTASTIC Theme. Do you think that in <br />the next version you could try and have it<br />
                        Multilanguage. Because I have nothing bad to <br />say about this theme. Thank a million!
                        <br />
                        <span className="font-semibold mt-2">Mark Jance / Facebook</span>
                    </p>
                </ div>
                <div className="lg:w-1/3">
                    <div className="flex items-center space-x-2">
                        <span className="text-orange-500 text-2xl">&#9733;</span>
                        <span className="text-orange-500 text-2xl">&#9733;</span>
                        <span className="text-orange-500 text-2xl">&#9733;</span>
                        <span className="text-orange-500 text-2xl">&#9733;</span>
                        <span className="text-orange-500 text-2xl">&#9733;</span>
                    </div>
                    <p className="text-sm lg:text-base text-neutral-700 mt-2">
                        This is a FANTASTIC Theme. Do you think that in <br />the next version you could try and have it<br />
                        Multilanguage. Because I have nothing bad to <br />say about this theme. Thank a million!
                        <br />
                        <span className="font-semibold mt-2">Mark Jance / Facebook</span>
                    </p>
                </ div>
            </div>

            <div className="conatiner ">
                <div className="bg-[rgb(249,221,220)]  flex flex-col items-center justify-center p-8 rounded-lg">
                    <p className="text-center text-xl lg:text-2xl font-semibold">
                        FIND OUT IF FEELAXO IS THE PERFECT MATCH FOR YOU
                    </p>
                    <p className="text-center mt-4">
                        Book your free demo today.
                    </p><br/>
                    <div className="flex items-center space-x-2">
                        <button className="px-4 py-2 border border-orange-500 text-orange-500 bg-white rounded-full flex items-center space-x-1 hover:bg-orange-500 hover:text-white">
                            Book Now
                            
                        </button>
                    </div>

                </div>

            </div>


        </>

    );
};

export default ForBusiness;
